import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Icon from 'shared/ui/icon';
import './vkButton.styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'vkButton',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        const props = __props;
        const onClickHandler = (url) => {
            if (props)
                window.location.replace(url);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["vk-button", { disabled: __props.disabled }]),
                onClick: _cache[0] || (_cache[0] = ($event) => (onClickHandler(props.url)))
            }, [
                _createVNode(_unref(Icon), {
                    type: "solid",
                    name: "vk"
                })
            ], 2));
        };
    }
});
