import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mobile-agreements-text" };
import DocLink from 'entities/docs/doc-link';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[0] || (_cache[0] = _createTextVNode(" Продолжая, Вы даете согласие на ")),
                _createVNode(_unref(DocLink), {
                    docType: "privacyPolicy",
                    text: "сбор, обработку и хранение персональных данных"
                })
            ]));
        };
    }
});
