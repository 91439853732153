import axios from 'axios';
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://auth.backend-tree.com'
});
export const login = async (credentials) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/login', credentials);
        if (response.data.error)
            return null;
        return response.data;
    }
    catch (error) {
        return error?.response?.data;
    }
};
export const register = async (credentials) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/register', credentials);
        if (response.data.error)
            return null;
        return response.data;
    }
    catch (error) {
        return error?.response?.data;
    }
};
export const logout = async () => {
    try {
        await AXIOS_INSTANCE.post('/api/v1/user/logout');
        return true;
    }
    catch (error) {
        return false;
    }
};
export const verifyRecoveryCode = async (code, clcaptcha) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/verify-token', {
            token: code,
            clcaptcha
        });
        return response.data.status;
    }
    catch (error) {
        return false;
    }
};
export const recoveryUpdatePassword = async (code, newPassword, clcaptcha) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/update-password-token', { token: code, new: newPassword, clcaptcha });
        return response.data.status;
    }
    catch (error) {
        return false;
    }
};
export const updatePassword = async (newPassword, oldPassword, accessToken) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/update-password', { new: newPassword, old: oldPassword }, { headers: { Authorization: `Bearer ${accessToken}` } });
        if (response.data.error)
            return false;
        return response.data.status;
    }
    catch (error) {
        return false;
    }
};
export const resetPassword = async (email, clcaptchaToken) => {
    const body = {
        login: email,
        clcaptcha: clcaptchaToken
    };
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/reset-password', body);
        return response.data.status;
    }
    catch (error) {
        return false;
    }
};
export const refresh = async (refreshToken) => {
    try {
        const response = await AXIOS_INSTANCE.post('/api/v1/user/refresh', {}, { headers: { Authorization: `Bearer ${refreshToken}` } });
        return response.data;
    }
    catch (error) {
        return null;
    }
};
export const getVkAuthUrl = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/api/v1/user/vkurl');
        return response.data.vk_url;
    }
    catch (error) {
        return null;
    }
};
export const getProfile = async () => {
    try {
        const profile = await AXIOS_INSTANCE.get('/api/v1/user/profile');
        if (profile.data.status) {
            return profile.data.data;
        }
        return null;
    }
    catch (error) {
        return null;
    }
};
export const getCountry = async () => {
    try {
        const countryResponse = await AXIOS_INSTANCE.get('/current-country');
        return countryResponse.data.country.toLowerCase();
    }
    catch (error) {
        return null;
    }
};
export const verifyVkUser = async (query) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/api/v1/user/verify?q=${query}`);
        return response.data;
    }
    catch (error) {
        return {};
    }
};
