import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import './feedback-modal.scss';
import Modal from '@/shared/ui/modal/modal.vue';
import Button from '@/shared/ui/button';
import { reviewsVkLink } from 'shared/config';
export default /*@__PURE__*/ _defineComponent({
    __name: 'feedbackModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        function close() {
            emit('close');
        }
        function redirectToFeedback() {
            window.open(reviewsVkLink, '_blank');
        }
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createBlock(Modal, {
                    key: 0,
                    title: "Нравится наш сайт?",
                    "image-src": require('./images/feedback.svg'),
                    onClose: close
                }, {
                    content: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createElementVNode("div", { class: "feedback-modal__content" }, " Пожалуйста, напишите хороший отзыв о нашем сайте! Он поможет радовать Вас низкими ценами и надежной доставкой! ", -1)
                    ])),
                    footer: _withCtx(() => [
                        _createVNode(_unref(Button), {
                            class: "feedback-modal__button",
                            onClick: redirectToFeedback
                        }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" Оставить отзыв ")
                            ])),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["image-src"]))
                : _createCommentVNode("", true);
        };
    }
});
