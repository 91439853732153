import { validateLength, validateEmailRegex, validatePassword, validatePasswordRepeat } from 'shared/utils';
import { reactive } from 'vue';
export function useSignupValidation(email, password, password2, username) {
    const errors = reactive({
        email: '',
        password: '',
        password2: '',
        username: ''
    });
    function setErrorToField(key, error) {
        errors[key] = error;
    }
    function validateRegisterEmail() {
        let errorMessage = '';
        errors.email = '';
        if (email.value.length === 0) {
            errorMessage = 'Поле пустое';
        }
        else if (!validateEmailRegex(email.value)) {
            errorMessage = 'Введённая почта неверная!';
        }
        else {
            errorMessage = '';
        }
        errors.email = errorMessage;
    }
    function validateRegisterPassword() {
        errors.password = validatePassword(password.value);
    }
    function validateRegisterPassword2() {
        errors.password2 = validatePasswordRepeat(password.value, password2.value);
    }
    function validateRegisterUsername() {
        let errorMessage = '';
        errors.username = '';
        if (username.value.length === 0) {
            errorMessage = 'Поле пустое';
        }
        else if (!validateLength(username.value, 4, 60)) {
            errorMessage = 'Длина никнейма от 4 символов';
        }
        else {
            errorMessage = '';
        }
        errors.username = errorMessage;
    }
    function validate() {
        validateRegisterEmail();
        validateRegisterPassword();
        validateRegisterPassword2();
        validateRegisterUsername();
        for (let key in errors) {
            if (errors[key])
                return false;
        }
        return true;
    }
    return {
        errors,
        validate,
        validateRegisterEmail,
        validateRegisterPassword,
        validateRegisterPassword2,
        validateRegisterUsername,
        setErrorToField
    };
}
