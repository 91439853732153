import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mobile-navigation-item" };
import Icon from 'shared/ui/icon';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["mobile-navigation-item-wrapper", { active: props.active }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_unref(Icon), {
                        type: "outline",
                        name: props.icon
                    }, null, 8, ["name"])
                ]),
                _createElementVNode("span", null, _toDisplayString(props.title), 1)
            ], 2));
        };
    }
});
