import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "profile-balance__data" };
const _hoisted_2 = { class: "profile-balance__data__value" };
const _hoisted_3 = { class: "profile-balance__button" };
import { computed } from 'vue';
import Icon from 'shared/ui/icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'profileBalance',
    props: {
        balance: {
            type: Number,
            default: 0
        },
        disabled: Boolean
    },
    setup(__props) {
        const props = __props;
        const formattedBalance = computed(() => {
            return new Intl.NumberFormat('ru-RU', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(props.balance);
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["profile-balance", { disabled: __props.disabled }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "profile-balance__data__header" }, "Ваш баланс", -1)),
                    _createElementVNode("div", _hoisted_2, _toDisplayString(formattedBalance.value) + " ₽", 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(Icon), {
                        type: "outline",
                        name: "plus"
                    })
                ])
            ], 2));
        };
    }
});
