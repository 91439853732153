import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "social-modal__footer" };
import './social-modal.scss';
import Modal from '@/shared/ui/modal/modal.vue';
import Button from '@/shared/ui/button';
import { vkGroupLink, telegramLink, youtubeLink } from 'shared/config';
export default /*@__PURE__*/ _defineComponent({
    __name: 'socialModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        function close() {
            emit('close');
        }
        function goToSocial(socialUrl) {
            window.open(socialUrl, '_blank');
        }
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createBlock(Modal, {
                    key: 0,
                    title: "Что тут у нас?",
                    "image-name": "notification",
                    onClose: close
                }, {
                    content: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("div", { class: "social-modal__content" }, [
                            _createElementVNode("p", null, " Промокоды, розыгрыши, мемы и новости — причины подписаться на наши социальные сети! ")
                        ], -1)
                    ])),
                    footer: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _createVNode(_unref(Button), {
                                "icon-type": "solid",
                                "right-icon": "telegram",
                                onClick: _cache[0] || (_cache[0] = ($event) => (goToSocial(_unref(telegramLink))))
                            }),
                            _createVNode(_unref(Button), {
                                "icon-type": "solid",
                                "right-icon": "vk",
                                onClick: _cache[1] || (_cache[1] = ($event) => (goToSocial(_unref(vkGroupLink))))
                            }),
                            _createVNode(_unref(Button), {
                                "icon-type": "solid",
                                "right-icon": "youtube",
                                onClick: _cache[2] || (_cache[2] = ($event) => (goToSocial(_unref(youtubeLink))))
                            })
                        ])
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true);
        };
    }
});
