import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "recovery__content" };
const _hoisted_2 = {
    key: 1,
    class: "recovery__code"
};
const _hoisted_3 = { class: "recovery__code__input" };
const _hoisted_4 = { class: "recovery__code__resend" };
const _hoisted_5 = { key: 0 };
const _hoisted_6 = {
    key: 2,
    class: "recovery__new-password"
};
const _hoisted_7 = {
    key: 0,
    class: "recovery__footer"
};
import './recovery.styles.scss';
import { ref, computed, reactive } from 'vue';
import { useDeviceTypeStore } from 'entities/device-type';
import { API } from 'shared/api';
import { useCaptcha } from 'widgets/auth-form/methods/useCaptcha';
import { validatePassword, validatePasswordRepeat } from 'shared/utils';
import { useNotificationStore } from 'entities/notification';
import AuthHeader from 'entities/auth/auth-header';
import Input from 'shared/ui/input';
import Button from 'shared/ui/button';
import CodeInput from 'shared/ui/code-input';
import ErrorMessage from 'shared/ui/error-message';
import DetailInfo from 'shared/ui/detail-info';
import MobileAgreementText from 'entities/auth/mobile-agreements-text';
import Captcha from 'shared/ui/captcha';
export default /*@__PURE__*/ _defineComponent({
    __name: 'recovery',
    emits: ['changeMethod'],
    setup(__props, { emit: __emit }) {
        const descriptions = [
            {
                desktop: 'Введите данные для восстановления аккаунта',
                mobile: 'Введите данные для восстановления'
            },
            {
                desktop: 'Укажите новый пароль Вашего аккаунта',
                mobile: 'Укажите новый пароль для аккаунта'
            }
        ];
        const step = ref(1);
        const email = ref('');
        const emailState = ref('default');
        const recoveryCode = ref('');
        const recoveryCodeStatus = ref('default');
        const newPassword = ref('');
        const repeatedNewPassword = ref('');
        const error = ref('');
        const isResendCode = ref(false);
        const resendCounter = ref(0);
        const resendCounterId = ref(0);
        const errorValidation = reactive({
            newPassword: '',
            repeatedNewPassword: ''
        });
        const deviceTypeStore = useDeviceTypeStore();
        const { captchaShow, captchaToken, loading, captchaCallback, openCaptcha } = useCaptcha();
        const buttonSize = computed(() => {
            if (deviceTypeStore.isMobile)
                return 'xs';
            return 'l';
        });
        const buttonText = computed(() => {
            if (step.value === 1) {
                if (deviceTypeStore.isMobile)
                    return 'ВОССТАНОВИТЬ ПАРОЛЬ';
                return 'ВОССТАНОВИТЬ';
            }
            else if (step.value === 2) {
                return 'ПРОДОЛЖИТЬ';
            }
            return 'ВОССТАНОВИТЬ';
        });
        const headerTitle = computed(() => {
            if (deviceTypeStore.isMobile)
                return 'ВОССТАНОВЛЕНИЕ ПАРОЛЯ';
            return 'ВОССТАНОВЛЕНИЕ';
        });
        const headerDescription = computed(() => {
            const validIndex = step.value < 3 ? 0 : 1;
            const type = deviceTypeStore.isMobile ? 'mobile' : 'desktop';
            return descriptions[validIndex][type] || '';
        });
        const newPasswordState = computed(() => {
            return errorValidation.newPassword ? 'error' : 'default';
        });
        const repeatedNewPasswordState = computed(() => {
            return errorValidation.repeatedNewPassword ? 'error' : 'default';
        });
        const isAllDataFilled = computed(() => {
            const error = errorValidation.newPassword || errorValidation.repeatedNewPassword;
            return step.value === 3
                ? newPassword.value && repeatedNewPassword.value && !error
                : true;
        });
        const startTimer = () => {
            resendCounter.value = 60;
            resendCounterId.value = setInterval(() => {
                resendCounter.value -= 1;
                if (resendCounter.value <= 0) {
                    clearInterval(resendCounterId.value);
                }
            }, 1000);
        };
        const sendEmail = async () => {
            const isReset = await API.auth.resetPassword(email.value, captchaToken.value);
            if (!isReset) {
                emailState.value = 'error';
                return;
            }
            step.value = 2;
            emailState.value = 'success';
            startTimer();
        };
        const sendCode = async () => {
            const isVerified = await API.auth.verifyRecoveryCode(recoveryCode.value, captchaToken.value);
            if (!isVerified) {
                recoveryCodeStatus.value = 'error';
                error.value = 'Неверный код. Попробуйте снова!';
                return;
            }
            step.value = 3;
        };
        const sendPassword = async () => {
            const isVerified = await API.auth.recoveryUpdatePassword(recoveryCode.value, newPassword.value, captchaToken.value);
            if (!isVerified) {
                error.value = 'Возникла ошибка при восстановлении пароля. Попробуйте снова!';
                return;
            }
            emit('changeMethod', 'signin');
            const notificationStore = useNotificationStore();
            notificationStore.setNotification({
                title: 'Успешно',
                description: 'Пароль успешно восстановлен',
                type: 'success'
            });
        };
        const recovery = async () => {
            loading.value = true;
            switch (step.value) {
                case 1:
                    await sendEmail();
                    break;
                case 2:
                    await sendCode();
                    break;
                case 3:
                    await sendPassword();
                    break;
            }
            loading.value = false;
        };
        const resendCode = () => {
            error.value = '';
            recoveryCodeStatus.value = 'default';
            isResendCode.value = true;
            openCaptcha();
        };
        const onCaptchaVerify = (token) => {
            const callback = isResendCode.value ? sendEmail : recovery;
            captchaCallback(token, callback);
            isResendCode.value = false;
        };
        const toStepOne = () => {
            clearData();
            step.value = 1;
        };
        const clearData = () => {
            email.value = '';
            emailState.value = 'default';
            recoveryCode.value = '';
            recoveryCodeStatus.value = 'default';
            newPassword.value = '';
            repeatedNewPassword.value = '';
            error.value = '';
            loading.value = false;
        };
        const submitHandler = () => {
            error.value = '';
            recoveryCodeStatus.value = 'default';
            if (step.value === 1)
                emailState.value = 'default';
            openCaptcha();
        };
        const validateNewPassword = () => {
            errorValidation.newPassword = validatePassword(newPassword.value);
        };
        const validateRepeatedNewPassword = () => {
            errorValidation.repeatedNewPassword = validatePasswordRepeat(newPassword.value, repeatedNewPassword.value);
        };
        const emit = __emit;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["recovery", `step-${step.value}`])
            }, [
                _createVNode(_unref(AuthHeader), {
                    title: headerTitle.value,
                    description: headerDescription.value
                }, null, 8, ["title", "description"]),
                _createElementVNode("div", _hoisted_1, [
                    (step.value !== 3)
                        ? (_openBlock(), _createBlock(_unref(Input), {
                            key: 0,
                            modelValue: email.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((email).value = $event)),
                            class: "recovery__email",
                            placeholder: "Email",
                            "left-icon": "envelope",
                            error: "Почта введена неверно!",
                            "right-icon": step.value === 2 ? 'editAlt' : undefined,
                            state: emailState.value,
                            disabled: _unref(loading),
                            inactive: emailState.value === 'success',
                            onRightIconClick: step.value === 2 ? toStepOne : undefined
                        }, null, 8, ["modelValue", "right-icon", "state", "disabled", "inactive", "onRightIconClick"]))
                        : _createCommentVNode("", true),
                    (step.value === 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "recovery__code-text" }, " На указанную почту был отправлен 6-значный код для восстановления пароля. Введите его в поля ниже: ", -1)),
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_unref(CodeInput), {
                                    class: _normalizeClass(["recovery__code__input-block", { disabled: _unref(loading) }]),
                                    inputCount: 6,
                                    "onUpdate:code": _cache[1] || (_cache[1] = (value) => (recoveryCode.value = value)),
                                    status: recoveryCodeStatus.value
                                }, null, 8, ["class", "status"]),
                                _createElementVNode("div", _hoisted_4, [
                                    (resendCounter.value > 0)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Повторная отправка через 00:" + _toDisplayString(resendCounter.value), 1))
                                        : (_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            class: _normalizeClass(["recovery__code__resend__link", { disabled: _unref(loading) }]),
                                            onClick: resendCode
                                        }, " Отправить снова ", 2))
                                ])
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (step.value === 3)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_unref(Input), {
                                modelValue: newPassword.value,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((newPassword).value = $event)),
                                type: "password",
                                "left-icon": "lock",
                                placeholder: "Новый пароль",
                                disabled: _unref(loading),
                                password: true,
                                state: newPasswordState.value,
                                error: errorValidation.newPassword,
                                onChange: validateNewPassword
                            }, null, 8, ["modelValue", "disabled", "state", "error"]),
                            _createVNode(_unref(Input), {
                                modelValue: repeatedNewPassword.value,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((repeatedNewPassword).value = $event)),
                                type: "password",
                                "left-icon": "lock",
                                placeholder: "Повторите новый пароль",
                                disabled: _unref(loading),
                                password: true,
                                state: repeatedNewPasswordState.value,
                                error: errorValidation.repeatedNewPassword,
                                onChange: validateRepeatedNewPassword
                            }, null, 8, ["modelValue", "disabled", "state", "error"])
                        ]))
                        : _createCommentVNode("", true),
                    (_unref(deviceTypeStore).isMobile && step.value !== 2)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 3,
                            class: "recovery__remembered-password",
                            onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('changeMethod', 'signin')))
                        }, " Я вспомнил пароль! "))
                        : _createCommentVNode("", true),
                    ((step.value === 2 && recoveryCodeStatus.value === 'error') ||
                        (step.value === 3 && error.value))
                        ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                            key: 4,
                            text: error.value
                        }, null, 8, ["text"]))
                        : _createCommentVNode("", true),
                    (_unref(captchaShow))
                        ? (_openBlock(), _createBlock(_unref(Captcha), {
                            key: 5,
                            onOnVerify: onCaptchaVerify
                        }))
                        : _createCommentVNode("", true),
                    _createVNode(_unref(Button), {
                        class: "recovery__button",
                        onClick: submitHandler,
                        size: buttonSize.value,
                        rightIcon: "arrowRight",
                        loading: _unref(loading),
                        disabled: !isAllDataFilled.value
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(buttonText.value), 1)
                        ]),
                        _: 1
                    }, 8, ["size", "loading", "disabled"]),
                    (_unref(deviceTypeStore).isMobile && step.value !== 3)
                        ? (_openBlock(), _createBlock(_unref(Button), {
                            key: 6,
                            class: "recovery__button__to-signin",
                            size: buttonSize.value,
                            rightIcon: "arrowRight",
                            disabled: _unref(loading),
                            onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('changeMethod', 'signin')))
                        }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode(" ВОЙТИ В АККАУНТ ")
                            ])),
                            _: 1
                        }, 8, ["size", "disabled"]))
                        : _createCommentVNode("", true)
                ]),
                ((!_unref(deviceTypeStore).isMobile && step.value !== 3) || _unref(deviceTypeStore).isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (!_unref(deviceTypeStore).isMobile)
                            ? (_openBlock(), _createBlock(_unref(DetailInfo), {
                                key: 0,
                                title: "У Вас нет аккаунта?",
                                description: "Зарегистрируйтесь прямо сейчас!",
                                disabled: _unref(loading),
                                onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('changeMethod', 'signin')))
                            }, null, 8, ["disabled"]))
                            : _createCommentVNode("", true),
                        (_unref(deviceTypeStore).isMobile)
                            ? (_openBlock(), _createBlock(_unref(MobileAgreementText), { key: 1 }))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
