var GlobalIcons;
(function (GlobalIcons) {
    GlobalIcons["transfer"] = "transfer";
    GlobalIcons["premium"] = "premium";
    GlobalIcons["logpass"] = "logpass";
    GlobalIcons["fastpass"] = "fastpass";
    GlobalIcons["checkmark"] = "checkmark";
    GlobalIcons["robux"] = "robux";
    GlobalIcons["superPass"] = "superpass";
})(GlobalIcons || (GlobalIcons = {}));
var BaseIcons;
(function (BaseIcons) {
    BaseIcons["add"] = "add";
    BaseIcons["alarm"] = "alarm";
    BaseIcons["android"] = "android";
    BaseIcons["appStore"] = "app-store";
    BaseIcons["apple"] = "apple";
    BaseIcons["apps"] = "apps";
    BaseIcons["arrowDown"] = "arrow-down";
    BaseIcons["arrowLeft"] = "arrow-left";
    BaseIcons["arrowRight"] = "arrow-right";
    BaseIcons["arrowUp"] = "arrow-up";
    BaseIcons["atSign"] = "at-sign";
    BaseIcons["attach"] = "attach";
    BaseIcons["award"] = "award";
    BaseIcons["bank"] = "bank";
    BaseIcons["backspace"] = "backspace";
    BaseIcons["bag"] = "bag";
    BaseIcons["binocular"] = "binocular";
    BaseIcons["bookmark"] = "bookmark";
    BaseIcons["box"] = "box";
    BaseIcons["bullhorn"] = "bullhorn";
    BaseIcons["calendar"] = "calendar";
    BaseIcons["cancel"] = "cancel";
    BaseIcons["card"] = "card";
    BaseIcons["caretDown"] = "caret-down";
    BaseIcons["caretLeft"] = "caret-left";
    BaseIcons["caretRight"] = "caret-right";
    BaseIcons["caretUp"] = "caret-up";
    BaseIcons["chartPieAlt"] = "chart-pie-alt";
    BaseIcons["chartPie"] = "chart-pie";
    BaseIcons["discord"] = "discord";
    BaseIcons["chat"] = "chat";
    BaseIcons["check"] = "check";
    BaseIcons["checkedBox"] = "checked-box";
    BaseIcons["chrome"] = "chrome";
    BaseIcons["clipboardAlt"] = "clipboard-alt";
    BaseIcons["clipboard"] = "clipboard";
    BaseIcons["clock"] = "clock";
    BaseIcons["cloudCheck"] = "cloud-check";
    BaseIcons["cloudDownload"] = "cloud-download";
    BaseIcons["cloudOff"] = "cloud-off";
    BaseIcons["cloudUpload"] = "cloud-upload";
    BaseIcons["cloud"] = "cloud";
    BaseIcons["close"] = "close";
    BaseIcons["collapse"] = "collapse";
    BaseIcons["columns"] = "columns";
    BaseIcons["cookie"] = "cookie";
    BaseIcons["commentBlock"] = "comment-block";
    BaseIcons["commentMinus"] = "comment-minus";
    BaseIcons["commentPlus"] = "comment-plus";
    BaseIcons["comment"] = "comment";
    BaseIcons["contacts"] = "contacts";
    BaseIcons["copy"] = "copy";
    BaseIcons["cross"] = "cross";
    BaseIcons["currentLocation"] = "current-location";
    BaseIcons["cursor"] = "cursor";
    BaseIcons["diamond"] = "diamond";
    BaseIcons["dislike"] = "dislike";
    BaseIcons["document"] = "document";
    BaseIcons["download"] = "download";
    BaseIcons["editAlt"] = "edit-alt";
    BaseIcons["edit"] = "edit";
    BaseIcons["envelopeOpen"] = "envelope-open";
    BaseIcons["envelope"] = "envelope";
    BaseIcons["exchange"] = "exchange";
    BaseIcons["expand"] = "expand";
    BaseIcons["explore"] = "explore";
    BaseIcons["eyeClosed"] = "eye-closed";
    BaseIcons["eye"] = "eye";
    BaseIcons["facebook"] = "facebook";
    BaseIcons["fileDownload"] = "file-download";
    BaseIcons["fileUpload"] = "file-upload";
    BaseIcons["fileUser"] = "file-user";
    BaseIcons["file"] = "file";
    BaseIcons["filter"] = "filter";
    BaseIcons["fire"] = "fire";
    BaseIcons["flask"] = "flask";
    BaseIcons["forward"] = "forward";
    BaseIcons["globe"] = "globe";
    BaseIcons["gmail"] = "gmail";
    BaseIcons["googleAlt"] = "google-alt";
    BaseIcons["googlePlay"] = "google-play";
    BaseIcons["google"] = "google";
    BaseIcons["gift"] = "gift";
    BaseIcons["gamepad"] = "gamepad";
    BaseIcons["headphone"] = "headphone";
    BaseIcons["headset"] = "headset";
    BaseIcons["heartOff"] = "heart-off";
    BaseIcons["heartPlus"] = "heart-plus";
    BaseIcons["heart"] = "heart";
    BaseIcons["heartbeat"] = "heartbeat";
    BaseIcons["history"] = "history";
    BaseIcons["home"] = "home";
    BaseIcons["hotspot"] = "hotspot";
    BaseIcons["image"] = "image";
    BaseIcons["infoCircle"] = "info-circle";
    BaseIcons["infoRect"] = "info-rect";
    BaseIcons["infoTriangle"] = "info-triangle";
    BaseIcons["instagram"] = "instagram";
    BaseIcons["invoice"] = "invoice";
    BaseIcons["key"] = "key";
    BaseIcons["layout"] = "layout";
    BaseIcons["lightbulbAlt"] = "lightbulb-alt";
    BaseIcons["lightbulbOff"] = "lightbulb-off";
    BaseIcons["lightbulb"] = "lightbulb";
    BaseIcons["lightningAlt"] = "lightning-alt";
    BaseIcons["lightning"] = "lightning";
    BaseIcons["like"] = "like";
    BaseIcons["linkedin"] = "linkedin";
    BaseIcons["locationCheck"] = "location-check";
    BaseIcons["locationPlus"] = "location-plus";
    BaseIcons["locationQuestion"] = "location-question";
    BaseIcons["location"] = "location";
    BaseIcons["lockTime"] = "lock-time";
    BaseIcons["lock"] = "lock";
    BaseIcons["login"] = "login";
    BaseIcons["logout"] = "logout";
    BaseIcons["mapLocation"] = "map-location";
    BaseIcons["menu"] = "menu";
    BaseIcons["microphoneOff"] = "microphone-off";
    BaseIcons["microphone"] = "microphone";
    BaseIcons["moon"] = "moon";
    BaseIcons["move"] = "move";
    BaseIcons["music"] = "music";
    BaseIcons["navigation"] = "navigation";
    BaseIcons["notificationOff"] = "notification-off";
    BaseIcons["notificationOn"] = "notification-on";
    BaseIcons["notification"] = "notification";
    BaseIcons["other1"] = "other-1";
    BaseIcons["other2"] = "other-2";
    BaseIcons["palette"] = "palette";
    BaseIcons["pause"] = "pause";
    BaseIcons["phoneIn"] = "phone-in";
    BaseIcons["phoneMiss"] = "phone-miss";
    BaseIcons["phoneOff"] = "phone-off";
    BaseIcons["phoneOut"] = "phone-out";
    BaseIcons["phone"] = "phone";
    BaseIcons["picture"] = "picture";
    BaseIcons["pin"] = "pin";
    BaseIcons["pinterest"] = "pinterest";
    BaseIcons["play"] = "play";
    BaseIcons["plus"] = "plus";
    BaseIcons["powerButton"] = "power-button";
    BaseIcons["present"] = "present";
    BaseIcons["pulse"] = "pulse";
    BaseIcons["refresh"] = "refresh";
    BaseIcons["reply"] = "reply";
    BaseIcons["rows"] = "rows";
    BaseIcons["sandWatch"] = "sand-watch";
    BaseIcons["save"] = "save";
    BaseIcons["search"] = "search";
    BaseIcons["send"] = "send";
    BaseIcons["server"] = "server";
    BaseIcons["settingsAdjust"] = "settings-adjust";
    BaseIcons["settingsAlt"] = "settings-alt";
    BaseIcons["settings"] = "settings";
    BaseIcons["shareBox"] = "share-box";
    BaseIcons["share"] = "share";
    BaseIcons["shield"] = "shield";
    BaseIcons["shoppingBag"] = "shopping-bag";
    BaseIcons["shoppingBasket"] = "shopping-basket";
    BaseIcons["shoppingCancel"] = "shopping-basket-cancel";
    BaseIcons["shoppingCart"] = "shopping-cart";
    BaseIcons["shuffle"] = "shuffle";
    BaseIcons["skipNext"] = "skip-next";
    BaseIcons["skipPrev"] = "skip-prev";
    BaseIcons["sort"] = "sort";
    BaseIcons["starHalf"] = "star-half";
    BaseIcons["star"] = "star";
    BaseIcons["sun"] = "sun";
    BaseIcons["telegram"] = "telegram";
    BaseIcons["timer"] = "timer";
    BaseIcons["trashAlt"] = "trash-alt";
    BaseIcons["trash"] = "trash";
    BaseIcons["tumblr"] = "tumblr";
    BaseIcons["twitch"] = "twitch";
    BaseIcons["twitter"] = "twitter";
    BaseIcons["unlock"] = "unlock";
    BaseIcons["upload"] = "upload";
    BaseIcons["userBlock"] = "user-block";
    BaseIcons["userClock"] = "user-clock";
    BaseIcons["userPlus"] = "user-plus";
    BaseIcons["user"] = "user";
    BaseIcons["vk"] = "vk";
    BaseIcons["volumeDown"] = "volume-down";
    BaseIcons["volumeOff"] = "volume-off";
    BaseIcons["volumeUp"] = "volume-up";
    BaseIcons["wallet"] = "wallet";
    BaseIcons["wechat"] = "wechat";
    BaseIcons["whatsapp"] = "whatsapp";
    BaseIcons["windows"] = "windows";
    BaseIcons["youtube"] = "youtube";
    BaseIcons["zoomIn"] = "zoom-in";
    BaseIcons["zoomOut"] = "zoom-out";
    BaseIcons["bannerArrow"] = "banner-arrow";
    BaseIcons["separator"] = "separator";
    BaseIcons["calculator"] = "calculator";
})(BaseIcons || (BaseIcons = {}));
export { BaseIcons, GlobalIcons };
