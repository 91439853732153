import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "auth-header" };
const _hoisted_2 = { class: "auth-header__icon" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "auth-header__text" };
const _hoisted_5 = { class: "auth-header__text__title" };
const _hoisted_6 = { class: "auth-header__text__description" };
import './authHeader.styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'authHeader',
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                        src: require('./images/upload.svg')
                    }, null, 8, _hoisted_3)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(__props.title), 1),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(__props.description), 1)
                ])
            ]));
        };
    }
});
