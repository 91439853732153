import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "header" };
const _hoisted_2 = {
    key: 0,
    class: "header__tabs"
};
const _hoisted_3 = { class: "header__social" };
const _hoisted_4 = {
    key: 0,
    class: "header__authorize"
};
const _hoisted_5 = {
    key: 0,
    class: "header__delimeter"
};
const _hoisted_6 = {
    key: 2,
    class: "header__decoration"
};
import './header.styles.scss';
import { inject, ref } from 'vue';
import Logo from 'shared/ui/logo';
import Tab from 'entities/navigation/tab/tab.vue';
import Button from 'shared/ui/button';
import ProfileBalance from 'entities/profile/balance';
import Avatar from 'entities/profile/avatar';
import { useDeviceTypeStore } from 'entities/device-type';
import { sessionModel, useSessionStore } from 'entities/session';
import { useRouter } from 'vue-router';
import { copyText } from 'shared/utils';
import ButtonUserProfile from 'entities/profile/button-user-profile/index';
import Social from '@/shared/ui/social/social.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'header',
    setup(__props) {
        const deviceTypeStore = useDeviceTypeStore();
        const sessionStore = useSessionStore();
        const router = useRouter();
        const isDropMenuActive = ref(false);
        const openModalWindow = inject('openModalWindow');
        const avatarUrl = inject('avatarUrl');
        const handleProfileMouseOver = (value) => {
            isDropMenuActive.value = value;
        };
        const logout = async () => {
            await sessionModel.logout();
            window.location.replace('/');
        };
        const replenishmentHandler = () => {
            if (sessionStore.isAuthorized) {
                router.push('/replenishment');
                return;
            }
            openModalWindow('signin');
        };
        return (_ctx, _cache) => {
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(Logo), {
                    class: "header__logo",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$router.push('/')))
                }),
                (!_unref(deviceTypeStore).isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(Tab, { to: "/" }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode("ГЛАВНАЯ")
                            ])),
                            _: 1
                        }),
                        _createVNode(Tab, { to: "/purchase" }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode("КУПИТЬ РОБУКСЫ")
                            ])),
                            _: 1
                        }),
                        _createVNode(Tab, {
                            to: "/replenishment",
                            onClick: _withModifiers(replenishmentHandler, ["prevent"])
                        }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode(" ПОПОЛНИТЬ БАЛАНС ")
                            ])),
                            _: 1
                        }),
                        _createVNode(Tab, { to: "/faq" }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("ПОМОЩЬ")
                            ])),
                            _: 1
                        })
                    ]))
                    : _createCommentVNode("", true),
                (!_unref(sessionStore).loading)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["header__info", { auth: _unref(sessionStore).isAuthorized }])
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(Social)
                        ]),
                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "header__delimeter" }, null, -1)),
                        (!_unref(sessionStore).isAuthorized)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_unref(Button), {
                                    size: "l",
                                    type: "primary",
                                    class: "header__authorize__button",
                                    "mobile-size": "xs",
                                    onClick: _cache[1] || (_cache[1] = ($event) => (_unref(openModalWindow)('signin')))
                                }, {
                                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode(" Вход ")
                                    ])),
                                    _: 1
                                }),
                                _createVNode(_unref(Button), {
                                    size: "l",
                                    type: "outline",
                                    class: "header__authorize__button signup",
                                    "mobile-size": "xs",
                                    onClick: _cache[2] || (_cache[2] = ($event) => (_unref(openModalWindow)('signup')))
                                }, {
                                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode(" Регистрация ")
                                    ])),
                                    _: 1
                                })
                            ]))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: "header__profile",
                                onMouseleave: _cache[7] || (_cache[7] = ($event) => (handleProfileMouseOver(false)))
                            }, [
                                _createVNode(_unref(ProfileBalance), {
                                    balance: _unref(sessionStore).profile.rub_balance,
                                    onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$router.push('/replenishment')))
                                }, null, 8, ["balance"]),
                                (!_unref(deviceTypeStore).isMobile)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                                    : _createCommentVNode("", true),
                                _createVNode(_component_router_link, { to: "/profile" }, {
                                    default: _withCtx(() => [
                                        (!_unref(deviceTypeStore).isMobile)
                                            ? (_openBlock(), _createBlock(_unref(Avatar), {
                                                key: 0,
                                                username: _unref(sessionStore).profile.username,
                                                "avatar-url": _unref(avatarUrl),
                                                onMouseover: _cache[4] || (_cache[4] = ($event) => (handleProfileMouseOver(true)))
                                            }, null, 8, ["username", "avatar-url"]))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                }),
                                (!_unref(deviceTypeStore).isMobile)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        class: _normalizeClass(["header__drop-menu", { active: isDropMenuActive.value }]),
                                        onMouseover: _cache[6] || (_cache[6] = ($event) => (handleProfileMouseOver(true)))
                                    }, [
                                        _createVNode(_unref(ButtonUserProfile), {
                                            image: "powerButton",
                                            title: "Выйти",
                                            type: "negative",
                                            class: "mobile-profile__cancel-btn",
                                            onClick: logout
                                        }),
                                        _createVNode(_unref(ButtonUserProfile), {
                                            image: "copy",
                                            title: "Копировать ID",
                                            type: "positive",
                                            class: "mobile-profile__cancel-btn",
                                            onClick: _cache[5] || (_cache[5] = ($event) => (_unref(copyText)(_unref(sessionStore).profile.user_id)))
                                        })
                                    ], 34))
                                    : _createCommentVNode("", true)
                            ], 32))
                    ], 2))
                    : _createCommentVNode("", true),
                (!_unref(deviceTypeStore).isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
