import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, unref as _unref } from "vue";
const _hoisted_1 = { class: "modal-wrapper" };
const _hoisted_2 = { class: "modal" };
const _hoisted_3 = { class: "modal__header" };
const _hoisted_4 = { class: "modal__header__info" };
const _hoisted_5 = { class: "modal__header__icon" };
const _hoisted_6 = ["src", "alt"];
const _hoisted_7 = { class: "modal__header__text" };
const _hoisted_8 = { class: "modal__header__text__title" };
const _hoisted_9 = {
    key: 0,
    class: "modal__header__text__description"
};
const _hoisted_10 = { class: "modal__content" };
const _hoisted_11 = { class: "modal__footer" };
import './modal.scss';
import Button from '@/shared/ui/button';
import Icon from '@/shared/ui/icon/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'modal',
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        imageSrc: {
            type: String,
            default: ''
        },
        imageName: {
            type: String,
            default: ''
        },
        imageType: {
            type: String,
            default: 'solid'
        },
        hideClose: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _renderSlot(_ctx.$slots, "header"),
                            (!_ctx.$slots.header)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("div", _hoisted_5, [
                                        (__props.imageName)
                                            ? (_openBlock(), _createBlock(Icon, {
                                                key: 0,
                                                type: __props.imageType,
                                                name: __props.imageName
                                            }, null, 8, ["type", "name"]))
                                            : _createCommentVNode("", true),
                                        (__props.imageSrc)
                                            ? (_openBlock(), _createElementBlock("img", {
                                                key: 1,
                                                src: __props.imageSrc,
                                                alt: __props.title
                                            }, null, 8, _hoisted_6))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_7, [
                                        _createElementVNode("span", _hoisted_8, _toDisplayString(__props.title), 1),
                                        (__props.description)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(__props.description), 1))
                                            : _createCommentVNode("", true)
                                    ])
                                ], 64))
                                : _createCommentVNode("", true)
                        ]),
                        (!__props.hideClose)
                            ? (_openBlock(), _createBlock(_unref(Button), {
                                key: 0,
                                type: "secondary",
                                leftIcon: "close",
                                size: "s",
                                class: "modal__close",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _renderSlot(_ctx.$slots, "content")
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                        _renderSlot(_ctx.$slots, "footer")
                    ])
                ])
            ]));
        };
    }
});
