import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from './assets/avatar-border.svg';
const _hoisted_1 = { class: "profile-avatar" };
const _hoisted_2 = { class: "profile-avatar__data" };
const _hoisted_3 = { class: "profile-avatar__data__nickname" };
const _hoisted_4 = {
    key: 0,
    class: "profile-avatar__image"
};
const _hoisted_5 = ["src"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'avatar',
    props: {
        username: String,
        avatarUrl: String
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(__props.username), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "profile-avatar__data__description" }, "Ваш профиль", -1))
                ]),
                (__props.avatarUrl)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _cache[1] || (_cache[1] = _createElementVNode("img", {
                            class: "profile-avatar__image__border",
                            src: _imports_0,
                            alt: "avatar-border",
                            srcset: ""
                        }, null, -1)),
                        _createElementVNode("img", {
                            class: "profile-avatar__image__avatar",
                            src: __props.avatarUrl,
                            alt: "",
                            srcset: ""
                        }, null, 8, _hoisted_5)
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
