import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "mobile-avatar__wrapper" };
const _hoisted_2 = { class: "mobile-avatar" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "mobile-avatar__image-front-block" };
const _hoisted_5 = ["src"];
const _hoisted_6 = { key: 0 };
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        avatarUrl: String,
        isItem: Boolean
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                        class: "mobile-avatar__image-bg",
                        src: __props.avatarUrl
                    }, null, 8, _hoisted_3),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("img", {
                            class: "mobile-avatar__image",
                            src: __props.avatarUrl
                        }, null, 8, _hoisted_5)
                    ])
                ]),
                (!__props.isItem)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Профиль"))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
