import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "auth-form-wrapper"
};
const _hoisted_2 = { class: "auth-form" };
const _hoisted_3 = { class: "auth-form__content" };
const _hoisted_4 = { class: "auth-form__sidebar" };
import { ref, onMounted } from 'vue';
import Button from 'shared/ui/button';
import Signin from './methods/signin';
import Signup from './methods/signup';
import Recovery from './methods/recovery';
import { useDeviceTypeStore } from 'entities/device-type';
import { sessionModel } from 'entities/session';
import MobileTouchWrapper from 'shared/ui/mobile-touch-wrapper';
import './authForm.styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'authForm',
    props: {
        method: {
            type: String,
            default: 'signup'
        }
    },
    emits: ['close', 'successSignUp'],
    setup(__props, { emit: __emit }) {
        const deviceTypeStore = useDeviceTypeStore();
        const emit = __emit;
        const props = __props;
        const localMethod = ref(props.method);
        onMounted(async () => {
            localMethod.value = props.method;
            await sessionModel.getVkAuthUrl();
        });
        function changeMethod(method) {
            localMethod.value = method;
        }
        function close() {
            emit('close');
        }
        return (_ctx, _cache) => {
            return (!_unref(deviceTypeStore).isMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            (localMethod.value === 'signin')
                                ? (_openBlock(), _createBlock(_unref(Signin), {
                                    key: 0,
                                    onChangeMethod: changeMethod,
                                    "on-close": close
                                }))
                                : _createCommentVNode("", true),
                            (localMethod.value === 'signup')
                                ? (_openBlock(), _createBlock(_unref(Signup), {
                                    key: 1,
                                    "on-close": close,
                                    onChangeMethod: changeMethod,
                                    onSuccess: _cache[0] || (_cache[0] = ($event) => (emit('successSignUp')))
                                }))
                                : _createCommentVNode("", true),
                            (localMethod.value === 'recovery')
                                ? (_openBlock(), _createBlock(_unref(Recovery), {
                                    key: 2,
                                    onChangeMethod: changeMethod,
                                    "on-close": close
                                }))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_unref(Button), {
                                type: "secondary",
                                leftIcon: "cross",
                                size: "s",
                                onClick: close
                            })
                        ])
                    ])
                ]))
                : (_openBlock(), _createBlock(_unref(MobileTouchWrapper), {
                    key: 1,
                    "on-close": close
                }, {
                    default: _withCtx(() => [
                        (localMethod.value === 'signin')
                            ? (_openBlock(), _createBlock(_unref(Signin), {
                                key: 0,
                                onChangeMethod: changeMethod,
                                "on-close": close
                            }))
                            : _createCommentVNode("", true),
                        (localMethod.value === 'signup')
                            ? (_openBlock(), _createBlock(_unref(Signup), {
                                key: 1,
                                "on-close": close,
                                onChangeMethod: changeMethod,
                                onSuccess: _cache[1] || (_cache[1] = ($event) => (emit('successSignUp')))
                            }))
                            : _createCommentVNode("", true),
                        (localMethod.value === 'recovery')
                            ? (_openBlock(), _createBlock(_unref(Recovery), {
                                key: 2,
                                onChangeMethod: changeMethod,
                                "on-close": close
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }));
        };
    }
});
