import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "global-wrapper" };
const _hoisted_2 = {
    key: 0,
    class: "auth-form-wrapper"
};
const _hoisted_3 = { style: { "width": "60%", "height": "200px", "display": "flex", "align-items": "center", "flex-direction": "column", "justify-content": "center", "background": "white", "border-radius": "14px" } };
const _hoisted_4 = {
    key: 0,
    style: { "margin-top": "20px", "font-size": "34px" }
};
const _hoisted_5 = { class: "content" };
import './styles.scss';
import { computed, provide, ref } from 'vue';
import { RouterView } from 'vue-router';
import { useSessionStore } from 'entities/session';
import { useDeviceTypeStore } from 'entities/device-type';
import Header from 'widgets/header';
import AuthForm from 'widgets/auth-form';
import MobileNavigation from 'widgets/mobile-navigation';
import MobileContacts from 'widgets/docs/mobile-contacts';
import MobilePrivacyPolicy from 'widgets/docs/mobile-privacy-policy';
import MobileTermsOfUse from 'widgets/docs/mobile-terms-of-use';
import Toast from '@/shared/ui/toast/index';
import FeedbackModal from '@/shared/ui/modal/feedback-modal';
import SocialModal from '@/shared/ui/modal/social-modal';
import PartnershipModal from '@/shared/ui/modal/partnership-modal';
import PromocodeModal from '@/shared/ui/modal/promocode-modal';
import GiftModal from '@/shared/ui/modal/gift-modal/giftModal.vue';
import BalanceModal from '@/shared/ui/modal/balance-modal/balanceModal.vue';
import SuperPassTimeModal from '@/shared/ui/modal/super-pass-time-modal';
import VLoader from '@/shared/ui/loader/VLoader.vue';
import SignupInfo from 'widgets/auth-form/info/signup-info';
import { useNotificationStore } from 'entities/notification';
import { useTransferPurchaseStore } from '@/entities/purchase/model/transferModel';
import { useIntervalFn } from '@vueuse/core/index';
import { sessionModel } from 'entities/session';
import { API } from '@/shared/api';
import { useHeadSettings } from '@/app/providers/useHeadSettings';
import { analytics } from 'shared/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        useHeadSettings();
        const modalWindow = ref('');
        const deviceTypeStore = useDeviceTypeStore();
        const sessionStore = useSessionStore();
        const notificationStore = useNotificationStore();
        const isLoading = ref(true);
        const isShowSignupInfo = ref(false);
        function openModalWindow(windowType) {
            modalWindow.value = windowType;
        }
        function showSignupInfo() {
            isShowSignupInfo.value = true;
        }
        function closeModalWindow() {
            modalWindow.value = '';
        }
        async function checkLoadingPage() {
            const purchaseStore = useTransferPurchaseStore();
            const profile = await sessionModel.fetchProfile();
            isLoading.value = false;
            const userId = profile?.user_id ?? '';
            const rate = await API.rates.getRates(userId);
            // Запускаем аналитику пользователя
            if (profile)
                analytics?.setUser(profile.user_id);
            purchaseStore.setRate(rate);
        }
        const avatarUrl = computed(() => {
            if (sessionStore.profile.avatar) {
                return sessionStore.profile.is_vk
                    ? sessionStore.profile.avatar
                    : require(`entities/profile/avatar/assets/avatar-${sessionStore.profile.avatar}.png`);
            }
            return '';
        });
        provide('openModalWindow', openModalWindow);
        provide('avatarUrl', avatarUrl);
        const purchaseStore = useTransferPurchaseStore();
        const isDeveloperMode = ref(false);
        const setActiveMode = () => {
            isDeveloperMode.value = false;
        };
        window.setActiveMode = setActiveMode;
        const timer = ref('');
        const updateTime = () => {
            const now = new Date();
            const endTime = new Date();
            // Устанавливаем часы и минуты
            endTime.setHours(17);
            endTime.setMinutes(30);
            endTime.setSeconds(0); // Устанавливаем секунды на 0
            if (now.getTime() >= endTime) {
                timer.value = '';
            }
            else {
                const remainingTimeMs = endTime - now.getTime();
                const hours = Math.floor((remainingTimeMs / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((remainingTimeMs / (1000 * 60)) % 60);
                const seconds = Math.floor((remainingTimeMs / 1000) % 60);
                const hoursFormatted = hours.toString().padStart(2, '0');
                const minutesFormatted = minutes.toString().padStart(2, '0');
                const secondsFormatted = seconds.toString().padStart(2, '0');
                timer.value = `${hoursFormatted} ч. ${minutesFormatted} мин. ${secondsFormatted} сек.`;
            }
        };
        updateTime();
        useIntervalFn(updateTime, 1000);
        const time = computed(() => timer.value);
        // Загрузка основных данных приложения
        checkLoadingPage();
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (isDeveloperMode.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _cache[8] || (_cache[8] = _createElementVNode("h1", null, "На сайте ведутся технические работы", -1)),
                            (time.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(time.value), 1))
                                : _createCommentVNode("", true)
                        ])
                    ]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["notification-wrapper", { show: _unref(notificationStore).notifications?.length }])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationStore).notifications, (notification) => {
                                return (_openBlock(), _createBlock(_unref(Toast), {
                                    id: notification.id,
                                    type: notification.type,
                                    title: notification.title,
                                    description: notification.description,
                                    delay: notification.delay
                                }, null, 8, ["id", "type", "title", "description", "delay"]));
                            }), 256))
                        ], 2),
                        (modalWindow.value === 'signin' ||
                            modalWindow.value === 'signup' ||
                            modalWindow.value === 'recovery')
                            ? (_openBlock(), _createBlock(_unref(AuthForm), {
                                key: 0,
                                method: modalWindow.value,
                                onClose: closeModalWindow,
                                onSuccessSignUp: showSignupInfo
                            }, null, 8, ["method"]))
                            : _createCommentVNode("", true),
                        _createVNode(_unref(SignupInfo), {
                            show: isShowSignupInfo.value,
                            onClose: _cache[0] || (_cache[0] = ($event) => (isShowSignupInfo.value = false))
                        }, null, 8, ["show"]),
                        (modalWindow.value === 'contacts')
                            ? (_openBlock(), _createBlock(_unref(MobileContacts), {
                                key: 1,
                                onClose: closeModalWindow
                            }))
                            : _createCommentVNode("", true),
                        (modalWindow.value === 'privacyPolicy')
                            ? (_openBlock(), _createBlock(_unref(MobilePrivacyPolicy), {
                                key: 2,
                                onClose: closeModalWindow
                            }))
                            : _createCommentVNode("", true),
                        (modalWindow.value === 'termsOfUse')
                            ? (_openBlock(), _createBlock(_unref(MobileTermsOfUse), {
                                key: 3,
                                onClose: closeModalWindow
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_unref(Header)),
                        (isLoading.value)
                            ? (_openBlock(), _createBlock(VLoader, { key: 4 }))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(_Transition, { name: "fade" }, {
                                default: _withCtx(() => [
                                    _createVNode(_unref(RouterView))
                                ]),
                                _: 1
                            })
                        ]),
                        (_unref(deviceTypeStore).isMobile)
                            ? (_openBlock(), _createBlock(_unref(MobileNavigation), { key: 5 }))
                            : _createCommentVNode("", true)
                    ], 64)),
                _createVNode(_unref(FeedbackModal), {
                    show: _unref(purchaseStore).modals.isShowFeedback,
                    onClose: _cache[1] || (_cache[1] = ($event) => (_unref(purchaseStore).hideModal('feedback')))
                }, null, 8, ["show"]),
                _createVNode(_unref(SocialModal), {
                    show: _unref(purchaseStore).modals.isShowSocial,
                    onClose: _cache[2] || (_cache[2] = ($event) => (_unref(purchaseStore).hideModal('social')))
                }, null, 8, ["show"]),
                _createVNode(_unref(PartnershipModal), {
                    show: _unref(purchaseStore).modals.isShowPartnership,
                    onClose: _cache[3] || (_cache[3] = ($event) => (_unref(purchaseStore).hideModal('partnership')))
                }, null, 8, ["show"]),
                _createVNode(_unref(PromocodeModal), {
                    show: _unref(purchaseStore).modals.isShowPromocode,
                    onClose: _cache[4] || (_cache[4] = ($event) => (_unref(purchaseStore).hideModal('promocode')))
                }, null, 8, ["show"]),
                _createVNode(GiftModal, {
                    show: _unref(purchaseStore).modals.isShowGift,
                    onClose: _cache[5] || (_cache[5] = ($event) => (_unref(purchaseStore).hideModal('gift')))
                }, null, 8, ["show"]),
                _createVNode(BalanceModal, {
                    show: _unref(purchaseStore).modals.isShowBalance,
                    onClose: _cache[6] || (_cache[6] = ($event) => (_unref(purchaseStore).hideModal('balance')))
                }, null, 8, ["show"]),
                _createVNode(_unref(SuperPassTimeModal), {
                    show: _unref(purchaseStore).modals.isShowSuperPass,
                    onClose: _cache[7] || (_cache[7] = ($event) => (_unref(purchaseStore).hideModal('superPass')))
                }, null, 8, ["show"])
            ]));
        };
    }
});
