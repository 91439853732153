import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { Routing } from 'pages';
import '../assets/fonts/Inter/inter.css';
import './styles/index.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Routing)));
        };
    }
});
