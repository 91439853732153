import { createRouter, createWebHistory } from 'vue-router';
import { routes } from 'pages';
import { AuthMiddleware } from 'processes/auth-middleware';
import { sessionModel } from 'entities/session';
import { API } from 'shared/api';
export const router = createRouter({
    history: createWebHistory(),
    routes,
    sensitive: true
});
Object.values(API).forEach((api) => {
    if (!api.AXIOS_INSTANCE)
        throw new Error('AXIOS_INSTANCE is not defined!');
    AuthMiddleware.init(api.AXIOS_INSTANCE, async () => {
        await sessionModel.logout(false);
    });
});
router.beforeEach(async (to) => {
    const isAuth = sessionModel.getRefreshToken();
    if (!isAuth && to.meta.auth) {
        return { name: 'home' };
    }
    const redirectRoutes = ['transfer', 'fastpass', 'logpass', 'premium'];
    if (redirectRoutes.filter((route) => to.fullPath.endsWith(route)).length > 0) {
        return { name: 'purchase' };
    }
});
