import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "mobile-contacts__content" };
import MobileTouchWrapper from 'shared/ui/mobile-touch-wrapper';
import Contacts from 'entities/docs/contacts';
import HeaderInfo from 'shared/ui/header-info';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(MobileTouchWrapper), {
                "on-close": () => emit('close')
            }, {
                default: _withCtx(() => [
                    _createVNode(_unref(HeaderInfo), {
                        class: "mobile-contacts__header",
                        icon: "document",
                        title: "Контакты",
                        description: "Ниже представлены контакты, по которым Вы можете связаться с нами в любое время"
                    }),
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_unref(Contacts))
                    ])
                ]),
                _: 1
            }, 8, ["on-close"]));
        };
    }
});
