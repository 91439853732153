import { defineComponent as _defineComponent } from 'vue';
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "captcha-block" };
const _hoisted_2 = { class: "captcha-wrapper" };
const _hoisted_3 = { id: "turnstile-box" };
import './captcha.styles.scss';
import { ref, onMounted } from 'vue';
const SCRIPT_SRC = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
const SITE_KEY = '0x4AAAAAAAFT-dxmsjeSfOMk';
export default /*@__PURE__*/ _defineComponent({
    __name: 'captcha',
    emits: ['onVerify'],
    setup(__props, { emit: __emit }) {
        const isCaptchaLoaded = ref(false);
        const captchaType = ref('');
        const emit = __emit;
        onMounted(() => {
            window.onloadTurnstileCallback = () => {
                renderCaptcha();
            };
            enableCloudFlareCaptcha();
        });
        const enableCloudFlareCaptcha = () => {
            captchaType.value = 'cloudflare';
            if (!window.turnstile) {
                loadCaptchaScript();
            }
            else {
                renderCaptcha();
            }
        };
        const renderCaptcha = () => {
            if (!window.turnstile)
                return;
            window.turnstile.render('#turnstile-box', {
                sitekey: SITE_KEY,
                callback: (token) => {
                    emit('onVerify', token);
                }
            });
            setTimeout(() => {
                isCaptchaLoaded.value = true;
            }, 50);
        };
        const loadCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = SCRIPT_SRC;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
                        [_vShow, captchaType.value === 'cloudflare']
                    ])
                ])
            ]));
        };
    }
});
