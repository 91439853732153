import { defineComponent as _defineComponent } from 'vue';
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "toast__content" };
const _hoisted_2 = { class: "toast__content__icon" };
const _hoisted_3 = { class: "toast__content__icon__inner" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "toast__content__text" };
const _hoisted_6 = { class: "toast__content__text__title" };
const _hoisted_7 = { class: "toast__content__text__description" };
import './styles.scss';
import { computed } from 'vue';
import { useNotificationStore } from '@/entities/notification/model';
import Button from 'shared/ui/button';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        type: {
            type: String,
            default: 'success'
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: null
        },
        delay: {
            type: Number,
            default: null
        }
    },
    setup(__props) {
        const props = __props;
        const notification = useNotificationStore();
        const icon = computed(() => {
            return require(`./images/${props.type}.svg`);
        });
        const animationDuration = computed(() => {
            return props.delay / 1000;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["toast", { [__props.type]: __props.type }])
            }, [
                _createElementVNode("div", {
                    class: "toast__bg animation-start",
                    style: _normalizeStyle({ animationDuration: animationDuration.value + 's' })
                }, null, 4),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            (__props.type)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    src: icon.value
                                }, null, 8, _hoisted_4))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(__props.title), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(__props.description), 1)
                    ])
                ]),
                _createVNode(_unref(Button), {
                    type: "secondary",
                    size: "s",
                    class: "toast__close",
                    leftIcon: "close",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_unref(notification).resetNotification(__props.id)))
                })
            ], 2));
        };
    }
});
