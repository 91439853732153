import Routing from './index.vue';
import { BASE_ROUTES } from '../app/providers/const';
export const routes = [
    {
        path: '/',
        name: BASE_ROUTES.home,
        component: () => import(/* webpackChunkName: "main" */ './main')
    },
    {
        path: '/reviews',
        name: BASE_ROUTES.reviews,
        component: () => import(/* webpackChunkName: "main" */ './reviews')
    },
    {
        path: '/purchase/:method(transfer|fastpass|premium|logpass)?',
        name: BASE_ROUTES.purchase,
        component: () => import(/* webpackChunkName: "purchase" */ './purchase')
    },
    {
        path: '/replenishment',
        name: BASE_ROUTES.replenishment,
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "replenishment" */ './replenishment')
    },
    {
        path: '/profile/:successOrder(new-order)?',
        name: BASE_ROUTES.profile,
        meta: {
            auth: true
        },
        component: () => import(/* webpackChunkName: "profile" */ './profile')
    },
    {
        path: '/faq',
        name: BASE_ROUTES.faq,
        component: () => import(/* webpackChunkName: "faq" */ './faq'),
        meta: {
            auth: false
        }
    },
    {
        path: '/docs/:type(contacts|privacy-policy|terms-of-use)?',
        name: BASE_ROUTES.docs,
        component: () => import(/* webpackChunkName: "docs" */ './docs')
    },
    {
        path: '/vkauth',
        name: BASE_ROUTES.VKAuth,
        props: (route) => ({ query: route.query.q }),
        component: () => import('./vk-auth')
    },
    // Другие маршруты
    {
        path: '/payment',
        redirect: '/replenishment'
    },
    {
        path: '/:pathMatch(.*)*',
        name: BASE_ROUTES.notFound,
        meta: {
            layout: 'empty'
        },
        component: () => import(/* webpackChunkName: "not-found" */ './not-found')
    }
    // {
    //   path: '/:pathMatch(.*)*',
    //   redirect: to => {
    //     if (to.path.match(/\/(transfer|fastpass|logpass|premium)/)) {
    //       return '/purchase';
    //     } else {
    //       return '/';
    //     }
    //   }
    // }
];
export { Routing };
