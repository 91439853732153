import { isDevelopment } from '../config';
const windowAnalytics = window;
const ym = windowAnalytics.ym || (() => { });
const YM_COUNTER_ID = 95827043;
export const analytics = {
    send(event, params) {
        if (!isDevelopment) {
            console.log('----ANALYTICS---', event, params);
            ym(YM_COUNTER_ID, 'reachGoal', event, params);
        }
    },
    setUser(id) {
        if (!isDevelopment) {
            console.log('----ANALYTICS---', 'setUserID', id);
            ym(YM_COUNTER_ID, 'setUserID', id);
        }
    }
};
