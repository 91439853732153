import { defineStore } from 'pinia';
import { API } from 'shared/api';
import { capitalizeFirstLetter } from 'shared/utils';
const AMOUNT_WITH_INSTRUCTION = 373;
export const getUsers = async (nickname) => {
    const users = await API.pending.searchUsers(nickname);
    return users ?? [];
};
export const getPlaces = async (userId) => {
    const places = await API.pending.getPlaces(userId);
    if (!places)
        return null;
    return places;
};
export const useTransferPurchaseStore = defineStore('transferPurchase', {
    state: () => ({
        robux: 0,
        nickname: '',
        userId: '',
        placeId: '',
        placeName: '',
        placeUid: '',
        step: 1,
        promoCode: {
            promo: '',
            bonus: 0,
            bonusForGamepass: 0
        },
        rate: 0,
        rbxInStock: '0',
        gift: {
            code: '',
            active: false,
            rub: 0,
            robux: 0
        },
        modals: {
            isShowFeedback: false,
            isShowSocial: false,
            isShowPartnership: false,
            isShowPromocode: false,
            isShowGift: false,
            isShowBalance: false,
            isShowSuperPass: false,
            isShowFriends: false
        },
        search: {
            game: '',
            pass: ''
        },
        gamesList: [],
        method: 'transfer'
    }),
    getters: {
        // isAuthorized: (state) => state.authorized
        isShowGiftInstruction: (state) => state.gift.robux === AMOUNT_WITH_INSTRUCTION
    },
    actions: {
        setUserData(nickanme, userId) {
            this.nickname = nickanme;
            this.userId = userId;
        },
        setRobux(robux) {
            this.robux = robux;
        },
        setPlace(placeId, placeName, placeUid) {
            this.placeId = placeId;
            this.placeName = placeName;
            this.placeUid = placeUid;
        },
        setStep(step) {
            this.step = step;
        },
        setRate(rate) {
            this.rate = rate;
        },
        setRbxInStock(data) {
            this.rbxInStock = data;
        },
        setGiftData(data, key) {
            this.gift[key] = data;
        },
        setMethod(data) {
            this.method = data;
        },
        setPromoCode(promo, bonus, bonusForGamepass) {
            this.promoCode.promo = promo;
            this.promoCode.bonus = bonus;
            this.promoCode.bonusForGamepass = bonusForGamepass;
        },
        resetPromoCode() {
            this.promoCode = {
                promo: '',
                bonus: 0,
                bonusForGamepass: 0
            };
        },
        resetCacheShowModalOnce(modal) {
            if (!localStorage.getItem(`${modal}ModalIsReset_5`)) {
                localStorage.removeItem(`${modal}ModalShown`);
                localStorage.setItem(`${modal}ModalIsReset_5`, '1');
            }
        },
        showModal(modal, cache = false) {
            const capitalizedModal = capitalizeFirstLetter(modal);
            if (!cache) {
                this.modals[`isShow${capitalizedModal}`] = true;
                return;
            }
            if (!localStorage.getItem(`${modal}ModalShown`)) {
                this.modals[`isShow${capitalizedModal}`] = true;
            }
            localStorage.setItem(`${modal}ModalShown`, '1');
        },
        hideModal(modal) {
            const capitalizedModal = capitalizeFirstLetter(modal);
            this.modals[`isShow${capitalizedModal}`] = false;
        },
        async getGamesList() {
            if (this.gamesList.length)
                return this.gamesList;
            const { games } = (await API.pending.getGames()) || {};
            if (!games)
                return null;
            this.gamesList = games;
            return this.gamesList;
        }
    }
});
