import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import './tab.styles.scss';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'tab',
    props: {
        to: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const props = __props;
        const isActive = computed(() => {
            return props.active;
        });
        const redirectHandler = () => {
            if (props.reload) {
                window.location.replace(props.to);
            }
        };
        return (_ctx, _cache) => {
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createBlock(_component_router_link, {
                to: __props.to,
                class: "navigation-tab",
                "exact-active-class": "active",
                exact: "",
                onClick: redirectHandler
            }, {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
            }, 8, ["to"]));
        };
    }
});
