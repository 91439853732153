import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "contacts" };
const _hoisted_2 = { class: "contacts__item" };
const _hoisted_3 = { class: "contacts__item" };
const _hoisted_4 = { class: "contacts__item" };
const _hoisted_5 = ["href"];
import Icon from 'shared/ui/icon';
import { vkGroupLink } from 'shared/config';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_unref(Icon), {
                        type: "outline",
                        name: "server"
                    }),
                    _cache[0] || (_cache[0] = _createElementVNode("a", { href: "https://rbxtree.io/" }, "RBXTREE.IO", -1))
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(Icon), {
                        type: "outline",
                        name: "headset"
                    }),
                    _cache[1] || (_cache[1] = _createElementVNode("a", { href: "mailto:rbxtree@gmail.com" }, "RBXTREE@GMAIL.COM", -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_unref(Icon), {
                        type: "solid",
                        name: "vk"
                    }),
                    _createElementVNode("a", { href: _unref(vkGroupLink) }, "VK.COM/RBXTREE", 8, _hoisted_5)
                ])
            ]));
        };
    }
});
