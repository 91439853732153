import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["value", "placeholder", "type", "theme", "disabled"];
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 1,
    class: "promo"
};
import { ref, computed } from 'vue';
import './input.styles.scss';
import Icon from 'shared/ui/icon';
import ErrorMessage from '../error-message';
export default /*@__PURE__*/ _defineComponent({
    __name: 'input',
    props: {
        modelValue: String,
        placeholder: String,
        leftIcon: String,
        rightIcon: String,
        state: String,
        error: String,
        theme: String,
        disabled: Boolean,
        password: Boolean,
        onRightIconClick: Function,
        inactive: Boolean,
        type: {
            type: String,
            default: 'text'
        },
        miniVersion: {
            type: Boolean
        },
        bold: {
            type: Boolean
        },
        currency: {
            type: String
        },
        promo: {
            type: Number
        },
        highlightSuccessBody: {
            type: Boolean
        },
        rightIconOnlySuccess: {
            type: Boolean
        }
    },
    emits: ['update:modelValue', 'blur'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const inputValueHidden = ref(true);
        const isPassword = computed(() => {
            return props.type === 'password';
        });
        const inputType = computed(() => {
            if (!isPassword.value)
                return props.type;
            return inputValueHidden.value ? 'password' : 'text';
        });
        const validRightIcon = computed(() => {
            if (props.rightIcon) {
                return props.rightIcon;
            }
            if (props.password) {
                return inputValueHidden.value ? 'eyeClosed' : 'eye';
            }
            return '';
        });
        const handleInput = (event) => {
            const target = event.target;
            emit('update:modelValue', target.value);
        };
        const rightIconClick = () => {
            if (props.onRightIconClick) {
                props.onRightIconClick();
            }
            else if (props.password) {
                inputValueHidden.value = !inputValueHidden.value;
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["input-wrapper", { disabled: __props.disabled, clickable: isPassword.value || __props.onRightIconClick, inactive: __props.inactive }])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([
                        'input-block',
                        __props.theme,
                        __props.state,
                        { bold: __props.bold },
                        { mini: __props.miniVersion },
                        { 'has-text': __props.modelValue },
                        { 'success-body': __props.highlightSuccessBody }
                    ])
                }, [
                    (__props.leftIcon)
                        ? (_openBlock(), _createBlock(_unref(Icon), {
                            key: 0,
                            type: "solid",
                            name: __props.leftIcon,
                            class: "left-icon"
                        }, null, 8, ["name"]))
                        : _createCommentVNode("", true),
                    _createElementVNode("input", {
                        value: __props.modelValue,
                        onInput: handleInput,
                        onBlur: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('blur'))),
                        placeholder: __props.placeholder,
                        class: "input-field",
                        type: inputType.value,
                        theme: inputType.value,
                        disabled: __props.disabled
                    }, null, 40, _hoisted_1),
                    (__props.currency || __props.promo)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (__props.currency)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.currency), 1))
                                : _createCommentVNode("", true),
                            (__props.promo)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "+" + _toDisplayString(__props.promo) + " R$", 1))
                                : _createCommentVNode("", true)
                        ]))
                        : _createCommentVNode("", true),
                    (validRightIcon.value)
                        ? (_openBlock(), _createBlock(_unref(Icon), {
                            key: 2,
                            type: "solid",
                            name: validRightIcon.value,
                            class: _normalizeClass(["right-icon", { hide: __props.rightIconOnlySuccess }]),
                            onClick: rightIconClick
                        }, null, 8, ["name", "class"]))
                        : _createCommentVNode("", true)
                ], 2),
                (__props.error && __props.state === 'error')
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        class: "input-error",
                        type: "error",
                        text: __props.error,
                        mini: ""
                    }, null, 8, ["text"]))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
