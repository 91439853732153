/** API entrypoint */
export const isDevelopment = process.env.NODE_ENV !== 'production';
export const vkGroupLink = 'https://vk.com/rbxtree';
export const telegramLink = 'https://t.me/rbxtreegg';
export const discordLink = 'https://discord.com/invite/8mF9fjTnQy';
export const youtubeLink = 'https://www.youtube.com/@Rbxtreegg';
export const allSocialLink = 'https://linktr.ee/rbxtree';
export const promoLink = 'https://linktr.ee/rbxtree_free';
export const halloweenLink = 'https://linkbest.ru/rbxtree_free';
export const lotteryLink = 'https://t.me/rbxtreegg/1448';
export const reviewsVkLink = 'https://vk.com/reviews-196995578';
