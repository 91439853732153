import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "error-message__content" };
const _hoisted_2 = {
    key: 0,
    class: "error-message__title"
};
const _hoisted_3 = ["innerHTML"];
import Icon from 'shared/ui/icon';
import './errorMessage.styles.scss';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'errorMessage',
    props: {
        type: {
            type: String,
            default: 'error'
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        mini: {
            type: Boolean,
            default: false
        },
        revert: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const props = __props;
        const ICONS = {
            error: 'infoRect',
            warning: 'infoTriangle',
            info: 'infoCircle',
            success: 'infoCircle'
        };
        const icon = computed(() => {
            return ICONS[props.type];
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["error-message", [
                        __props.type,
                        {
                            mini: __props.mini,
                            revert: __props.revert
                        }
                    ]])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (__props.title)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.title), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("span", { innerHTML: __props.text }, null, 8, _hoisted_3)
                ]),
                _createVNode(_unref(Icon), {
                    class: "error-message__icon",
                    type: __props.type === 'info' ? 'outline' : 'solid',
                    name: icon.value
                }, null, 8, ["type", "name"])
            ], 2));
        };
    }
});
