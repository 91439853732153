import { sessionModel } from 'entities/session';
export const init = (instance, onAuthError) => {
    instance.interceptors.request.use(async (config) => {
        if (config.url?.includes('user/refresh')) {
            return config;
        }
        const store = sessionModel.useSessionStore();
        if (!store.authorized) {
            const accessToken = await sessionModel.refreshToken();
            if (!accessToken)
                return config;
        }
        const token = store.accessToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    instance.interceptors.response.use((response) => response, async (error) => {
        const originalRequest = error.config;
        const data = error?.response?.data;
        if (originalRequest._retry ||
            originalRequest.url.includes('user/refresh') ||
            originalRequest.url.includes('user/logout')) {
            return Promise.reject(error);
        }
        if (error.response?.status === 401 && !data.error) {
            originalRequest._retry = true;
            try {
                const accessToken = await sessionModel.refreshToken();
                if (!accessToken)
                    throw new Error();
                console.log('new access token: ' + accessToken);
                instance.defaults.headers.common.Authorization =
                    'Bearer ' + accessToken;
                return instance(originalRequest);
            }
            catch (refreshError) {
                onAuthError();
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    });
};
