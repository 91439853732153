import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "header-info__icon" };
const _hoisted_2 = { class: "header-info__text" };
const _hoisted_3 = {
    key: 0,
    class: "header-info__step"
};
const _hoisted_4 = { class: "header-info__text__description" };
import './styles.scss';
import Icon from 'shared/ui/icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        icon: {
            type: String,
            required: true,
            default: 'info'
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'secondary'
        },
        currentStep: {
            type: Number
        },
        totalSteps: {
            type: Number
        },
        visible: {
            type: Boolean,
            default: true
        },
        isMini: {
            type: Boolean,
            default: false
        },
        isH1: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["header-info show", [__props.type, { mini: __props.isMini }]])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_unref(Icon), {
                        type: "solid",
                        name: __props.icon
                    }, null, 8, ["name"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(__props.isH1 ? 'h1' : 'div'), { class: "header-info__text__title" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(__props.title) + " ", 1),
                            (__props.currentStep)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.currentStep) + "/" + _toDisplayString(__props.totalSteps), 1))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(__props.description), 1)
                ]),
                _renderSlot(_ctx.$slots, "default")
            ], 2));
        };
    }
});
