import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = { class: "detail-info__text" };
const _hoisted_3 = { class: "detail-info__text__title" };
const _hoisted_4 = { class: "detail-info__text__description" };
const _hoisted_5 = { key: 1 };
import Icon from 'shared/ui/icon';
import './detailInfo.styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'detailInfo',
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isMini: {
            type: Boolean,
            default: false
        }
    },
    setup(__props) {
        const supportHandler = () => {
            console.log('supportHandler');
        };
        return (_ctx, _cache) => {
            return (!__props.isMini)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["detail-info", { disabled: __props.disabled }]),
                    onClick: supportHandler
                }, [
                    _createElementVNode("img", {
                        class: "detail-info__img",
                        src: require('./images/island.svg'),
                        alt: ""
                    }, null, 8, _hoisted_1),
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(__props.title), 1),
                        _createElementVNode("span", _hoisted_4, [
                            (_ctx.$slots.description)
                                ? _renderSlot(_ctx.$slots, "description", { key: 0 })
                                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.description), 1))
                        ])
                    ]),
                    _createVNode(_unref(Icon), {
                        class: "detail-info__arrow",
                        type: "solid",
                        name: "caretRight"
                    })
                ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["mobile-detail-info-item-wrapper", { 'is-mini': __props.isMini }]),
                    onClick: supportHandler
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["mobile-item", { 'is-mini': __props.isMini }])
                    }, [
                        _createVNode(_unref(Icon), {
                            type: "solid",
                            name: "headset"
                        })
                    ], 2)
                ], 2));
        };
    }
});
