import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mobile-touch-wrapper" };
import { ref } from 'vue';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        onClose: {
            type: Function,
            required: true,
            default: () => { }
        }
    },
    setup(__props) {
        const props = __props;
        const touchElem = ref(null);
        const startY = ref(0);
        const currentTranslateY = ref(0);
        function applyInertia(startValueForInertia, startVelocity, friction, onInertiaLoop, onInertiaEnd, stopCondition) {
            let localVelocity = startVelocity;
            let localValue = startValueForInertia;
            const inertia = () => {
                localValue += localVelocity;
                onInertiaLoop(localValue);
                localVelocity *= friction;
                if (Math.abs(localVelocity) < 0.5 ||
                    Math.abs(localVelocity) > 1000 ||
                    stopCondition()) {
                    onInertiaEnd();
                }
                else {
                    requestAnimationFrame(inertia);
                }
            };
            requestAnimationFrame(inertia);
        }
        function goToStart() {
            if (currentTranslateY.value <= 0) {
                currentTranslateY.value = 0;
                return;
            }
            applyInertia(currentTranslateY.value, -30, 1.3, (newValue) => {
                currentTranslateY.value = newValue;
            }, () => {
                currentTranslateY.value = 0;
            }, () => currentTranslateY.value <= 0);
        }
        function goToEnd() {
            const elemHeight = touchElem.value?.clientHeight || 0;
            /* Ломает сброс состояние при свайпе за нижний край экрана */
            // if (currentTranslateY.value >= elemHeight) {
            //   currentTranslateY.value = elemHeight
            //   console.log(1)
            //   return
            // }
            applyInertia(currentTranslateY.value, 30, 1.3, (newValue) => {
                currentTranslateY.value = newValue;
            }, () => {
                currentTranslateY.value = elemHeight;
                props.onClose();
            }, () => currentTranslateY.value >= elemHeight);
        }
        const handleTouchStart = (event) => {
            event.stopPropagation();
            event.preventDefault();
            startY.value = event.touches[0].clientY;
        };
        const handleTouchEnd = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const elemHeight = touchElem.value?.clientHeight || 0;
            if (currentTranslateY.value > elemHeight / 2) {
                goToEnd();
            }
            else {
                goToStart();
            }
        };
        const handleTouchMove = (event) => {
            event.stopPropagation();
            event.preventDefault();
            const touchY = event.touches[0].clientY;
            if (touchY < startY.value)
                return;
            const movedY = touchY - startY.value;
            currentTranslateY.value = movedY;
        };
        const handleMouseDown = (event) => {
            event.stopPropagation();
            event.preventDefault();
            startY.value = event.clientY;
        };
        const handleMouseMove = (event) => {
            event.stopPropagation();
            event.preventDefault();
            if (event.buttons !== 1)
                return;
            const touchY = event.clientY;
            if (touchY < startY.value)
                return;
            const movedY = touchY - startY.value;
            currentTranslateY.value = movedY;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: "mobile-touch",
                    ref_key: "touchElem",
                    ref: touchElem,
                    style: _normalizeStyle({ transform: `translateY(${currentTranslateY.value}px)` })
                }, [
                    _createElementVNode("div", {
                        class: "mobile-touch-area",
                        onTouchstart: handleTouchStart,
                        onTouchmove: handleTouchMove,
                        onTouchend: handleTouchEnd,
                        onMousedown: handleMouseDown,
                        onMousemove: handleMouseMove,
                        onMouseup: handleTouchEnd
                    }, null, 32),
                    _renderSlot(_ctx.$slots, "default")
                ], 4)
            ]));
        };
    }
});
