import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = { key: 1 };
const _hoisted_2 = { class: "button-user-profile__title" };
const _hoisted_3 = {
    key: 0,
    class: "button-user-profile__data-count"
};
import { computed } from 'vue';
import Icon from 'shared/ui/icon';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        image: {
            type: String,
            default: 'timer'
        },
        dataCount: {
            type: Number,
            default: NaN
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'positive'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        imageText: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        const props = __props;
        const dataCountView = computed(() => {
            return !Number.isNaN(Number(props.dataCount));
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["button-user-profile", { disabled: __props.disabled }])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["button-user-profile__icon", { [__props.type]: true, dark: __props.imageText }])
                }, [
                    (!props.imageText)
                        ? (_openBlock(), _createBlock(_unref(Icon), {
                            key: 0,
                            type: "solid",
                            name: props.image
                        }, null, 8, ["name"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.imageText), 1))
                ], 2),
                _createElementVNode("span", _hoisted_2, _toDisplayString(__props.title), 1),
                (dataCountView.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", null, _toDisplayString(__props.dataCount), 1)
                    ]))
                    : _createCommentVNode("", true),
                _createVNode(_unref(Icon), {
                    type: "solid",
                    name: "caretRight",
                    class: "button-user-profile__arrow"
                })
            ], 2));
        };
    }
});
