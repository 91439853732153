import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "signup" };
const _hoisted_2 = { class: "signup__form" };
const _hoisted_3 = { class: "signup__form__inputs" };
const _hoisted_4 = {
    key: 0,
    class: "signup__agreement"
};
const _hoisted_5 = { class: "signup__agreement__text" };
const _hoisted_6 = { class: "signup__buttons" };
const _hoisted_7 = {
    key: 0,
    class: "signup__agreement"
};
const _hoisted_8 = { class: "signup__agreement__text" };
const _hoisted_9 = { class: "signup__footer" };
import './signup.styles.scss';
import { ref, computed, inject } from 'vue';
import { sessionModel, useSessionStore } from 'entities/session';
import { useDeviceTypeStore } from 'entities/device-type';
import { useCaptcha } from '@/widgets/auth-form/methods/useCaptcha';
import { useSignupValidation } from '@/widgets/auth-form/methods/signup/useSignupValidation';
import AuthHeader from 'entities/auth/auth-header';
import Input from 'shared/ui/input';
import Checkbox from 'shared/ui/checkbox';
import VkButton from 'shared/ui/vk-button';
import Button from 'shared/ui/button';
import ErrorMessage from 'shared/ui/error-message';
import DetailInfo from 'shared/ui/detail-info';
import MobileAgreementText from 'entities/auth/mobile-agreements-text';
import Captcha from 'shared/ui/captcha';
import DocLink from 'entities/docs/doc-link';
const SIGNUP_INFO = 'После регистрации Вы получите промокод на <span class="accent">100 бесплатных</span>  робуксов';
export default /*@__PURE__*/ _defineComponent({
    __name: 'signup',
    props: {
        onClose: {
            type: Function,
            required: true,
            default: () => { }
        }
    },
    emits: ['close', 'success'],
    setup(__props, { emit: __emit }) {
        const email = ref('');
        const login = ref('');
        const password = ref('');
        const repeatedPassword = ref('');
        const agreement = ref(false);
        const error = ref(false);
        const deviceTypeStore = useDeviceTypeStore();
        const { captchaShow, captchaToken, loading, captchaCallback, openCaptcha } = useCaptcha();
        const signUpValidation = useSignupValidation(email, password, repeatedPassword, login);
        const sessionStore = useSessionStore();
        const analytics = inject('analytics');
        const emits = __emit;
        const buttonSize = computed(() => {
            if (deviceTypeStore.isMobile)
                return 'xs';
            return 'l';
        });
        const props = __props;
        const signup = async () => {
            if (!signUpValidation.validate())
                return;
            error.value = false;
            const isRegistered = await sessionModel.signUp(email.value, login.value, password.value, captchaToken.value);
            loading.value = false;
            if (isRegistered?.error) {
                for (const key in isRegistered.error) {
                    if (isRegistered.error.hasOwnProperty(key)) {
                        const formattedError = isRegistered.error[key].charAt(0).toUpperCase() +
                            isRegistered.error[key].slice(1);
                        signUpValidation.setErrorToField(key, formattedError);
                    }
                }
                analytics?.send('login_error', {
                    type: 'reg',
                    method: 'email',
                    message: isRegistered.error
                });
                return;
            }
            analytics?.setUser(sessionStore.profile?.user_id);
            analytics?.send('login', {
                type: 'reg',
                method: 'email',
                user_id: sessionStore.profile?.user_id
            });
            emits('success');
            props.onClose();
        };
        const isAllDataFilled = computed(() => {
            return (email.value &&
                login.value &&
                password.value &&
                repeatedPassword.value &&
                agreement.value);
        });
        const emailState = computed(() => {
            return signUpValidation.errors.email ? 'error' : 'default';
        });
        const loginState = computed(() => {
            return signUpValidation.errors.username ? 'error' : 'default';
        });
        const passwordState = computed(() => {
            return signUpValidation.errors.password ? 'error' : 'default';
        });
        const repeatedPasswordState = computed(() => {
            return signUpValidation.errors.password2 ? 'error' : 'default';
        });
        const vkUrl = computed(() => {
            return sessionStore.vkAuthUrl;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(AuthHeader), {
                    title: "СОЗДАНИЕ АККАУНТА",
                    description: "Введите указанные данные для регистрации"
                }),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(Input), {
                            modelValue: email.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((email).value = $event)),
                            "left-icon": "envelope",
                            placeholder: "Email",
                            state: emailState.value,
                            disabled: _unref(loading),
                            error: _unref(signUpValidation).errors.email,
                            onChange: _cache[1] || (_cache[1] = ($event) => (_unref(signUpValidation).validateRegisterEmail()))
                        }, null, 8, ["modelValue", "state", "disabled", "error"]),
                        _createVNode(_unref(Input), {
                            modelValue: login.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((login).value = $event)),
                            "left-icon": "user",
                            placeholder: "Логин",
                            state: loginState.value,
                            disabled: _unref(loading),
                            error: _unref(signUpValidation).errors.username,
                            onChange: _cache[3] || (_cache[3] = ($event) => (_unref(signUpValidation).validateRegisterUsername()))
                        }, null, 8, ["modelValue", "state", "disabled", "error"]),
                        _createVNode(_unref(Input), {
                            modelValue: password.value,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((password).value = $event)),
                            type: "password",
                            "left-icon": "lock",
                            placeholder: "Пароль",
                            state: passwordState.value,
                            disabled: _unref(loading),
                            password: "",
                            error: _unref(signUpValidation).errors.password,
                            onChange: _cache[5] || (_cache[5] = ($event) => (_unref(signUpValidation).validateRegisterPassword()))
                        }, null, 8, ["modelValue", "state", "disabled", "error"]),
                        _createVNode(_unref(Input), {
                            modelValue: repeatedPassword.value,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((repeatedPassword).value = $event)),
                            type: "password",
                            "left-icon": "lock",
                            placeholder: "Повторите пароль",
                            state: repeatedPasswordState.value,
                            disabled: _unref(loading),
                            password: "",
                            error: _unref(signUpValidation).errors.password2,
                            onChange: _cache[7] || (_cache[7] = ($event) => (_unref(signUpValidation).validateRegisterPassword2()))
                        }, null, 8, ["modelValue", "state", "disabled", "error"]),
                        (_unref(deviceTypeStore).isMobile && _unref(captchaShow))
                            ? (_openBlock(), _createBlock(_unref(Captcha), {
                                key: 0,
                                onOnVerify: _cache[8] || (_cache[8] = (token) => _unref(captchaCallback)(token, signup)),
                                class: "signup__captcha"
                            }))
                            : _createCommentVNode("", true)
                    ]),
                    (!_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_unref(Checkbox), {
                                modelValue: agreement.value,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((agreement).value = $event)),
                                disabled: _unref(loading)
                            }, null, 8, ["modelValue", "disabled"]),
                            _createElementVNode("span", _hoisted_5, [
                                _cache[14] || (_cache[14] = _createTextVNode(" Я согласен с ")),
                                _createVNode(_unref(DocLink), {
                                    class: "agreement__link",
                                    docType: "termsOfUse",
                                    text: "пользовательским соглашением"
                                }),
                                _cache[15] || (_cache[15] = _createTextVNode(" и ")),
                                _createVNode(_unref(DocLink), {
                                    class: "agreement__link",
                                    docType: "privacyPolicy",
                                    text: "политикой конфиденциальности"
                                })
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (!_unref(deviceTypeStore).isMobile && _unref(captchaShow))
                        ? (_openBlock(), _createBlock(_unref(Captcha), {
                            key: 1,
                            onOnVerify: _cache[10] || (_cache[10] = (token) => _unref(captchaCallback)(token, signup)),
                            class: "signup__captcha"
                        }))
                        : _createCommentVNode("", true),
                    (error.value)
                        ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                            key: 2,
                            text: "Проверьте правильность введенных данных!"
                        }))
                        : _createCommentVNode("", true)
                ]),
                _createVNode(_unref(ErrorMessage), {
                    class: "signup__info",
                    type: "success",
                    text: SIGNUP_INFO,
                    revert: ""
                }),
                _createElementVNode("div", _hoisted_6, [
                    (_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_unref(Checkbox), {
                                modelValue: agreement.value,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((agreement).value = $event)),
                                disabled: _unref(loading)
                            }, null, 8, ["modelValue", "disabled"]),
                            _createElementVNode("span", _hoisted_8, [
                                _cache[16] || (_cache[16] = _createTextVNode(" Я согласен с ")),
                                _createVNode(_unref(DocLink), {
                                    class: "agreement__link",
                                    docType: "termsOfUse",
                                    text: "пользовательским соглашением"
                                }),
                                _cache[17] || (_cache[17] = _createTextVNode(" и ")),
                                _createVNode(_unref(DocLink), {
                                    class: "agreement__link",
                                    docType: "privacyPolicy",
                                    text: "политикой конфиденциальности"
                                })
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (!_unref(loading))
                        ? (_openBlock(), _createBlock(_unref(VkButton), {
                            key: 1,
                            url: vkUrl.value
                        }, null, 8, ["url"]))
                        : _createCommentVNode("", true),
                    _createVNode(_unref(Button), {
                        class: "signup__buttons__registration",
                        type: "primary",
                        size: buttonSize.value,
                        rightIcon: !_unref(deviceTypeStore).isMobile ? 'arrowRight' : 'userPlus',
                        loading: _unref(loading),
                        onClick: signup,
                        disabled: !isAllDataFilled.value
                    }, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" ЗАРЕГИСТРИРОВАТЬСЯ "),
                            _createElementVNode("span", { class: "accent" }, "+100 R$", -1)
                        ])),
                        _: 1
                    }, 8, ["size", "rightIcon", "loading", "disabled"]),
                    (_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(Button), {
                            key: 2,
                            class: "signup__buttons__login",
                            onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.$emit('changeMethod', 'signin'))),
                            type: "primary",
                            size: buttonSize.value,
                            "right-icon": "arrowRight",
                            disabled: _unref(loading)
                        }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                                _createTextVNode(" ВОЙТИ В АККАУНТ ")
                            ])),
                            _: 1
                        }, 8, ["size", "disabled"]))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                    (!_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(DetailInfo), {
                            key: 0,
                            title: "Уже есть аккаунт?",
                            description: "Перейти на страницу авторизации",
                            disabled: _unref(loading),
                            onClick: _cache[13] || (_cache[13] = ($event) => (_ctx.$emit('changeMethod', 'signin')))
                        }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                    (_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(MobileAgreementText), { key: 1 }))
                        : _createCommentVNode("", true)
                ])
            ]));
        };
    }
});
