import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "social" };
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
import Icon from '@/shared/ui/icon';
import { vkGroupLink, telegramLink, youtubeLink } from 'shared/config';
export default /*@__PURE__*/ _defineComponent({
    __name: 'social',
    setup(__props) {
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("a", {
                    href: _unref(telegramLink),
                    target: "_blank",
                    class: "social__btn"
                }, [
                    _createVNode(_unref(Icon), {
                        type: "solid",
                        name: "telegram"
                    })
                ], 8, _hoisted_2),
                _createElementVNode("a", {
                    href: _unref(vkGroupLink),
                    target: "_blank",
                    class: "social__btn"
                }, [
                    _createVNode(_unref(Icon), {
                        type: "solid",
                        name: "vk"
                    })
                ], 8, _hoisted_3),
                _createElementVNode("a", {
                    href: _unref(youtubeLink),
                    target: "_blank",
                    class: "social__btn"
                }, [
                    _createVNode(_unref(Icon), {
                        type: "solid",
                        name: "youtube"
                    })
                ], 8, _hoisted_4)
            ]));
        };
    }
});
