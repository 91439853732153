export var BASE_ROUTES;
(function (BASE_ROUTES) {
    BASE_ROUTES["home"] = "home";
    BASE_ROUTES["reviews"] = "reviews";
    BASE_ROUTES["purchase"] = "purchase";
    BASE_ROUTES["replenishment"] = "replenishment";
    BASE_ROUTES["profile"] = "profile";
    BASE_ROUTES["faq"] = "faq";
    BASE_ROUTES["docs"] = "docs";
    BASE_ROUTES["contacts"] = "contacts";
    BASE_ROUTES["payment"] = "payment";
    BASE_ROUTES["VKAuth"] = "VKAuth";
    BASE_ROUTES["privacy-policy"] = "privacy-policy";
    BASE_ROUTES["terms-of-use"] = "terms-of-use";
    BASE_ROUTES["notFound"] = "not-found";
})(BASE_ROUTES || (BASE_ROUTES = {}));
export const META_CONFIG = {
    [BASE_ROUTES.home]: {
        title: 'Купить робуксы Роблокс на RbxTree.io по низкой цене',
        description: 'Покупай робуксы трансфер и подарочные карты Роблокс моментально и безопасно. Много положительных отзывов реальных клиентов! Следи за акциями Дерева Робуксов!'
    },
    [BASE_ROUTES.purchase]: {
        title: () => 'Купить робуксы Роблокс дёшево на RbxTree.io',
        description: () => META_CONFIG[BASE_ROUTES.home]?.description
    },
    [BASE_ROUTES.replenishment]: {
        title: 'Пополнение баланса для покупки робуксов на RbxTree.io',
        description: 'Пополняйте баланс в личном кабинете, чтобы быстро и удобно купить робуксы для аккаунта Роблокс. Моментальное зачисление и безопасные способы оплаты: МИР, СБП, VISA, MASTERCARD'
    },
    [BASE_ROUTES.faq]: {
        title: 'Часто задаваемые вопросы и помощь на RbxTree.io',
        description: 'Найдите ответы на популярные вопросы о покупке робуксов, акциях и поддержке. Узнайте больше о доверии к RbxTree.io, бесплатных робуксах и способах решения проблем.'
    },
    [BASE_ROUTES.contacts]: {
        title: 'Документы и контакты на RbxTree.io',
        description: 'Ознакомьтесь с документами сайта RbxTree.io и получите полную контактную информацию: адрес, email и социальные сети. Надежность и прозрачность для каждого пользователя.'
    },
    [BASE_ROUTES['privacy-policy']]: {
        title: 'Политика конфиденциальности RbxTree.io',
        description: 'Ознакомьтесь с политикой конфиденциальности RbxTree.io: условия обработки персональных данных, использование cookies и права пользователей.'
    },
    [BASE_ROUTES['terms-of-use']]: {
        title: 'Пользовательское соглашение RbxTree.io',
        description: 'Ознакомьтесь с условиями использования сайта RbxTree.io: права и обязанности пользователей и оператора, порядок вступления соглашения в силу.'
    },
    [BASE_ROUTES.profile]: {
        title: 'Профиль пользователя на RbxTree.io',
        description: 'Управляйте своим профилем на RbxTree.io: изменяйте настройки аккаунта, отслеживайте историю заказов и создавайте новые заказы. Всё в одном месте!'
    },
    [BASE_ROUTES.notFound]: {
        title: 'Страница не найдена (404) |  RbxTree.io',
        description: 'К сожалению, запрашиваемая страница не найдена. Попробуйте вернуться на главную страницу.'
    }
};
