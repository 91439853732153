import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "balance-modal__content" };
import './balance-modal.scss';
import Modal from '@/shared/ui/modal/modal.vue';
import Button from '@/shared/ui/button';
import { useTransferPurchaseStore } from '@/entities/purchase/model/transferModel';
import { useRouter } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'balanceModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const purchaseStore = useTransferPurchaseStore();
        const router = useRouter();
        function close() {
            emit('close');
        }
        function onRedirect() {
            purchaseStore.hideModal('balance');
            router.push('/replenishment');
        }
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createBlock(Modal, {
                    key: 0,
                    title: "Для покупки пополните баланс",
                    "image-name": "shoppingCancel",
                    onClose: close
                }, {
                    content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _cache[0] || (_cache[0] = _createTextVNode(" У Вас недостаточно средств на балансе. Чтобы купить подарочную карту на ")),
                            _createElementVNode("span", null, _toDisplayString(_unref(purchaseStore).gift.robux) + " робуксов", 1),
                            _cache[1] || (_cache[1] = _createTextVNode(" , пополните баланс на ")),
                            _createElementVNode("span", null, _toDisplayString(_unref(purchaseStore).gift.rub) + " рублей", 1)
                        ])
                    ]),
                    footer: _withCtx(() => [
                        _createVNode(_unref(Button), {
                            class: "balance-modal__button",
                            onClick: onRedirect
                        }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode(" Пополните баланс ")
                            ])),
                            _: 1
                        })
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true);
        };
    }
});
