import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import './super-pass-time-modal.scss';
import Modal from '@/shared/ui/modal/modal.vue';
import Button from '@/shared/ui/button';
import { useRouter } from 'vue-router';
import { useTransferPurchaseStore } from '@/entities/purchase/model/transferModel';
export default /*@__PURE__*/ _defineComponent({
    __name: 'super-pass-time-modal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const router = useRouter();
        const purchaseStore = useTransferPurchaseStore();
        const emit = __emit;
        function close() {
            emit('close');
        }
        function redirectToPurchase() {
            router.push({ name: 'purchase' });
            purchaseStore.hideModal('superPass');
        }
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createBlock(Modal, {
                    key: 0,
                    title: "Покупка суперпасса",
                    "image-src": require('./images/flash-circle.svg'),
                    onClose: close
                }, {
                    content: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createElementVNode("div", { class: "super-pass-time-modal__content" }, [
                            _createTextVNode(" Этот метод работает с "),
                            _createElementVNode("strong", null, "10:00"),
                            _createTextVNode(" до "),
                            _createElementVNode("strong", null, "24:00"),
                            _createTextVNode(" по Московскому времени. Пожалуйста, повтори попытку утром. ")
                        ], -1)
                    ])),
                    footer: _withCtx(() => [
                        _createVNode(_unref(Button), {
                            class: "super-pass-time-modal__button",
                            "right-icon": "shoppingBasket",
                            "icon-type": "solid",
                            onClick: redirectToPurchase
                        }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" Купить робуксы другим методом ")
                            ])),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["image-src"]))
                : _createCommentVNode("", true);
        };
    }
});
