import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "code-container" };
const _hoisted_2 = ["onUpdate:modelValue", "onKeydown", "onInput"];
import { ref, watch, computed } from 'vue';
import './codeInput.styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'codeInput',
    props: {
        inputCount: {
            type: Number,
            default: 4
        },
        status: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'success', 'error'].includes(value)
        }
    },
    emits: ['update:code'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const codes = ref(Array(props.inputCount).fill(''));
        const codeInputs = ref(new Array(props.inputCount).fill(null));
        const assembledCode = computed(() => codes.value.join(''));
        watch(assembledCode, (newCode) => {
            emit('update:code', newCode);
        });
        const handleKeyDown = (index, event) => {
            if (event.key === 'Backspace' && codes.value[index] === '' && index > 0) {
                codeInputs.value[index - 1]?.focus();
            }
        };
        const handleInputChange = (index, event) => {
            const input = event.target;
            if (!/^[0-9]$/.test(input.value)) {
                input.value = '';
            }
            else {
                codes.value[index] = input.value;
                if (index < props.inputCount - 1) {
                    codeInputs.value[index + 1]?.focus();
                }
            }
        };
        const handlePaste = (event) => {
            event.preventDefault();
            const pastedData = event.clipboardData?.getData('text');
            if (!pastedData)
                return;
            const filteredData = pastedData
                .split('')
                .filter((char) => /^[0-9]$/.test(char));
            for (let i = 0; i < props.inputCount; i++) {
                codes.value[i] = filteredData[i] || '';
                if (codes.value[i]) {
                    codeInputs.value[i]?.focus();
                }
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.inputCount, (n) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["input-wrapper", [__props.status, codes.value[n - 1] ? 'filled' : '']]),
                        key: n
                    }, [
                        _withDirectives(_createElementVNode("input", {
                            class: _normalizeClass(["code-input", [__props.status, codes.value[n - 1] ? 'filled' : '']]),
                            "onUpdate:modelValue": ($event) => ((codes.value[n - 1]) = $event),
                            ref_for: true,
                            ref: (el) => (codeInputs.value[n - 1] = el),
                            onKeydown: ($event) => (handleKeyDown(n - 1, $event)),
                            onInput: ($event) => (handleInputChange(n - 1, $event)),
                            onPaste: handlePaste,
                            maxlength: "1",
                            placeholder: "0"
                        }, null, 42, _hoisted_2), [
                            [_vModelText, codes.value[n - 1]]
                        ])
                    ], 2));
                }), 128))
            ]));
        };
    }
});
