import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "gift-modal__body" };
const _hoisted_2 = { class: "gift-modal__code" };
const _hoisted_3 = {
    key: 0,
    class: "gift-modal__instruction"
};
const _hoisted_4 = ["src"];
import './gift-modal.scss';
import Modal from '@/shared/ui/modal/modal.vue';
import Button from '@/shared/ui/button';
import { useTransferPurchaseStore } from '@/entities/purchase/model/transferModel';
import { copyText } from '@/shared/utils';
import { useRouter } from 'vue-router';
import { useDeviceTypeStore } from '@/entities/device-type';
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'giftModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const router = useRouter();
        const purchaseStore = useTransferPurchaseStore();
        const deviceTypeStore = useDeviceTypeStore();
        const instruction = computed(() => {
            const name = `instruction${deviceTypeStore.isMobile ? '-mobile' : ''}`;
            return require(`../gift-modal/gif/${name}.gif`);
        });
        function close() {
            emit('close');
            router.push('/profile');
        }
        function goToRoblox() {
            window.open('https://www.roblox.com/redeem', '_blank');
        }
        return (_ctx, _cache) => {
            return (__props.show)
                ? (_openBlock(), _createBlock(Modal, {
                    key: 0,
                    title: "Код вашей подарочной карты",
                    "image-name": "gift",
                    class: "gift-modal",
                    onClose: close
                }, {
                    content: _withCtx(() => [
                        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "gift-modal__content" }, [
                            _createElementVNode("span", null, " Скопируйте код и введите его в игре Roblox. Вы можете снова открыть код в истории платежей ")
                        ], -1)),
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(purchaseStore).gift.code), 1),
                            _createVNode(_unref(Button), {
                                class: "gift-modal__copy",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_unref(copyText)(_unref(purchaseStore).gift.code, 'Подарочный код успешно скопирован')))
                            }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode(" Копировать ")
                                ])),
                                _: 1
                            })
                        ]),
                        (_unref(purchaseStore).isShowGiftInstruction)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _cache[2] || (_cache[2] = _createElementVNode("span", null, "Внимательно ознакомьтесь с видео-инструкцией", -1)),
                                _createElementVNode("img", {
                                    src: instruction.value,
                                    alt: "instruction"
                                }, null, 8, _hoisted_4),
                                _cache[3] || (_cache[3] = _createElementVNode("span", null, " Перейдите на сайт Roblox и выполните все действия, указанные в видео-инструкции ", -1))
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    footer: _withCtx(() => [
                        _createVNode(_unref(Button), {
                            class: "gift-modal__button",
                            onClick: goToRoblox
                        }, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode(" Перейти на сайт Roblox ")
                            ])),
                            _: 1
                        })
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true);
        };
    }
});
