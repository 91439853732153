import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "mobile-navigation" };
import { computed, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSessionStore } from '@/entities/session';
import MobileNavigationItem from 'entities/navigation/mobile-navigation-item';
import MobileAvatar from 'entities/profile/mobile-avatar';
import './styles.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        const route = useRoute();
        const router = useRouter();
        const store = useSessionStore();
        const openModalWindow = inject('openModalWindow');
        const avatarUrl = inject('avatarUrl');
        const isMainPage = computed(() => {
            const path = route.path;
            return path === '/';
        });
        const isFaq = computed(() => {
            const path = route.path;
            return path === '/faq';
        });
        const profileHandler = () => {
            if (store.isAuthorized) {
                router.push('/profile');
                return;
            }
            openModalWindow('signin');
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(MobileNavigationItem), {
                    title: "Главная",
                    icon: "home",
                    active: isMainPage.value,
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$router.push('/')))
                }, null, 8, ["active"]),
                _createVNode(_unref(MobileNavigationItem), {
                    title: "Помощь",
                    icon: "infoCircle",
                    active: isFaq.value,
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.$router.push('/faq')))
                }, null, 8, ["active"]),
                (!_unref(avatarUrl))
                    ? (_openBlock(), _createBlock(_unref(MobileNavigationItem), {
                        key: 0,
                        title: "Профиль",
                        icon: "user",
                        onClick: profileHandler
                    }))
                    : (_openBlock(), _createBlock(_unref(MobileAvatar), {
                        key: 1,
                        "avatar-url": _unref(avatarUrl),
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$router.push('/profile')))
                    }, null, 8, ["avatar-url"])),
                false
                    ? (_openBlock(), _createBlock(_unref(MobileNavigationItem), {
                        key: 2,
                        title: "Помощь",
                        icon: "headset"
                    }))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
