import { API } from 'shared/api';
import { defineStore } from 'pinia';
import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });
export const setRefreshToken = (token) => {
    const date = new Date();
    date.setDate(date.getDate() + 60);
    cookies.set('refresh', token, { expires: date });
};
export const getRefreshToken = () => {
    return cookies.get('refresh');
};
const removeRefreshToken = () => {
    cookies.set('refresh', '');
};
export const fetchProfile = async () => {
    const store = useSessionStore();
    store.loading = !store.isAuthorized;
    const profile = await API.auth.getProfile();
    if (profile) {
        store.setUserProfile(profile);
    }
    store.loading = false;
    return profile;
};
export const signIn = async (login, password, clcaptcha) => {
    const store = useSessionStore();
    try {
        const credentials = await API.auth.login({ login, password, clcaptcha });
        if (!credentials)
            throw new Error();
        if (credentials?.error)
            return credentials;
        store.setAccessToken(credentials.auth_token.access);
        setRefreshToken(credentials.auth_token.refresh);
        store.setUserProfile(credentials.data);
        store.setAuthorized(true);
    }
    catch (error) {
        removeRefreshToken();
        store.setAuthorized(false);
    }
};
export const signUp = async (email, username, password, clcaptcha) => {
    const store = useSessionStore();
    try {
        const credentials = await API.auth.register({
            email,
            username,
            password,
            clcaptcha
        });
        if (!credentials)
            throw new Error();
        if (credentials?.error)
            return credentials;
        store.setAccessToken(credentials.auth_token.access);
        setRefreshToken(credentials.auth_token.refresh);
        store.setUserProfile(credentials.data);
        store.setAuthorized(true);
    }
    catch (error) {
        console.error(error);
    }
};
export const logout = async (isNeedToSendRequest = true) => {
    if (isNeedToSendRequest) {
        await API.auth.logout();
    }
    const store = useSessionStore();
    removeRefreshToken();
    store.setAuthorized(false);
    store.setUserProfile(USER_PROFILE_EMPTY);
    return true;
};
export const refreshToken = async () => {
    const store = useSessionStore();
    try {
        const oldRefreshToken = getRefreshToken();
        if (!oldRefreshToken)
            throw new Error();
        const data = await API.auth.refresh(oldRefreshToken);
        if (!data)
            throw new Error();
        store.setAccessToken(data.auth_token.access);
        setRefreshToken(data.auth_token.refresh);
        store.setAuthorized(true);
        return data.auth_token.access;
    }
    catch (error) {
        removeRefreshToken();
        store.setAuthorized(false);
        return null;
    }
};
export const getVkAuthUrl = async () => {
    const store = useSessionStore();
    try {
        const data = await API.auth.getVkAuthUrl();
        if (!data)
            throw new Error();
        store.setVkAuthUrl(data);
        store.setVkAuth();
        return data;
    }
    catch (error) {
        return null;
    }
};
export const updatePassword = async (newPassword, oldPassword) => {
    const store = useSessionStore();
    try {
        const credentials = await API.auth.updatePassword(newPassword, oldPassword, store.accessToken);
        if (!credentials)
            throw new Error();
        return true;
    }
    catch (error) {
        return false;
    }
};
const USER_PROFILE_EMPTY = {
    avatar: null,
    country: undefined,
    email: '',
    is_vk: false,
    public_username: '',
    rub_balance: 0,
    user_id: '',
    username: '',
    username_lowercase: ''
};
export const useSessionStore = defineStore('session', {
    state: () => ({
        authorized: false,
        loading: false,
        accessToken: '',
        profile: {
            avatar: null,
            country: undefined,
            email: '',
            is_vk: false,
            public_username: '',
            rub_balance: 0,
            user_id: '',
            username: '',
            username_lowercase: ''
        },
        vkAuthUrl: ''
    }),
    getters: {
        isAuthorized: (state) => state.authorized
    },
    actions: {
        setAuthorized(value) {
            this.authorized = value;
        },
        setAccessToken(token) {
            this.accessToken = token;
        },
        removeAccessToken() {
            this.accessToken = '';
        },
        setUserProfile(profile) {
            Object.keys(this.profile).forEach((key) => {
                if (key in profile) {
                    this.profile[key] = profile[key];
                }
                this.profile.rub_balance =
                    Math.floor(this.profile.rub_balance * 100) / 100;
            });
        },
        setVkAuthUrl(value) {
            this.vkAuthUrl = value;
        },
        setVkAuth() {
            this.profile.is_vk = true;
        }
    }
});
