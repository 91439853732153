import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import './button.scss';
import { computed } from 'vue';
import Icon from 'shared/ui/icon';
import { useDeviceTypeStore } from '@/entities/device-type';
export default /*@__PURE__*/ _defineComponent({
    __name: 'button',
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        /**
         * - xl - 60px height
         * - l - 54px height
         * - m - 46px height
         * - s - 42px height
         * - xs - 38px height */
        size: {
            type: String,
            default: 'xl'
        },
        mobileSize: {
            type: String,
            default: 'l'
        },
        leftIcon: {
            type: String
        },
        rightIcon: {
            type: String
        },
        iconType: {
            type: String,
            default: 'outline'
        },
        disabled: Boolean,
        loading: Boolean
    },
    setup(__props) {
        const props = __props;
        const deviceType = useDeviceTypeStore();
        const adaptiveSize = computed(() => deviceType.isTablet ? props.mobileSize : props.size);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["button", [`button_${__props.type}`, `size_${adaptiveSize.value}`, { disabled: __props.disabled, loading: __props.loading }]])
            }, [
                (__props.leftIcon && !__props.loading)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        type: __props.iconType,
                        name: __props.leftIcon
                    }, null, 8, ["type", "name"]))
                    : _createCommentVNode("", true),
                (!__props.loading)
                    ? _renderSlot(_ctx.$slots, "default", { key: 1 })
                    : _createCommentVNode("", true),
                (__props.rightIcon && !__props.loading)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 2,
                        type: __props.iconType,
                        name: __props.rightIcon
                    }, null, 8, ["type", "name"]))
                    : _createCommentVNode("", true),
                (__props.loading)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 3,
                        class: "button__refresh-icon",
                        type: "solid",
                        name: "refresh"
                    }))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
