import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import Icon from 'shared/ui/icon';
import './checkbox.scss';
export default /*@__PURE__*/ _defineComponent({
    __name: 'checkbox',
    props: {
        modelValue: {
            type: Boolean,
            default: false,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const handleToggle = () => {
            if (!props.disabled) {
                emit('update:modelValue', !props.modelValue);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["checkbox", { checked: __props.modelValue, disabled: __props.disabled }]),
                onClick: handleToggle
            }, [
                (__props.modelValue)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        type: "global",
                        name: "checkmark"
                    }))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
