import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import './styles.scss';
import { inject } from 'vue';
import { useRouter } from 'vue-router';
import { useDeviceTypeStore } from 'entities/device-type';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        text: {
            type: String,
            default: 'пользовательское соглашение'
        },
        docType: {
            type: String,
            required: true,
            default: 'contacts'
        }
    },
    setup(__props) {
        const props = __props;
        const deviceType = useDeviceTypeStore();
        const router = useRouter();
        const openModalWindow = inject('openModalWindow');
        const modalToLink = {
            contacts: 'contacts',
            privacyPolicy: 'privacy-policy',
            termsOfUse: 'terms-of-use'
        };
        function linkClick() {
            if (deviceType.isMobile) {
                openModalWindow(props.docType);
            }
            else {
                router.push({ name: 'docs', params: { type: modalToLink[props.docType] } });
            }
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("a", {
                class: "doc-link",
                onClick: linkClick
            }, _toDisplayString(__props.text), 1));
        };
    }
});
