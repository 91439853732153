import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import './icon.styles.module.scss';
import { computed } from 'vue';
import { Icons } from './types';
import { GlobalIcons } from './icons.enum';
export default /*@__PURE__*/ _defineComponent({
    __name: 'icon',
    props: {
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'outline'
        },
        opacity: {
            type: Number,
            default: 1
        }
    },
    setup(__props) {
        const props = __props;
        const iconComponent = computed(() => {
            const isGlobal = props.name in GlobalIcons;
            const name = Icons[props.name];
            if (isGlobal) {
                return require(`@/assets/icons/global/${name}.svg`);
            }
            return require(`@/assets/icons/${props.type}/${name}.svg`);
        });
        const transformSvg = (svg) => {
            const paths = svg.querySelectorAll('path');
            paths.forEach((path) => {
                path.setAttribute('fill', 'inherit');
            });
            return svg;
        };
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return (_openBlock(), _createBlock(_component_inline_svg, {
                src: iconComponent.value,
                class: "icon",
                "fill-opacity": __props.opacity,
                "stroke-opacity": __props.opacity,
                transformSource: transformSvg,
                fill: "white"
            }, null, 8, ["src", "fill-opacity", "stroke-opacity"]));
        };
    }
});
