import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "signin__form" };
const _hoisted_2 = { class: "signin__form__inputs" };
const _hoisted_3 = { class: "signin__buttons" };
const _hoisted_4 = { class: "signin__footer" };
import './signin.styles.scss';
import { ref, computed, onMounted, nextTick, reactive, inject } from 'vue';
import { useDeviceTypeStore } from 'entities/device-type';
import { useTransferPurchaseStore } from 'entities/purchase/model/transferModel';
import { sessionModel, useSessionStore } from 'entities/session';
import { useCaptcha } from 'widgets/auth-form/methods/useCaptcha';
import AuthHeader from 'entities/auth/auth-header';
import Input from 'shared/ui/input';
import Button from 'shared/ui/button';
import VkButton from 'shared/ui/vk-button';
import DetailInfo from 'shared/ui/detail-info';
import MobileAgreementText from 'entities/auth/mobile-agreements-text';
import Captcha from 'shared/ui/captcha';
export default /*@__PURE__*/ _defineComponent({
    __name: 'signin',
    props: {
        onClose: {
            type: Function,
            required: true,
            default: () => { }
        }
    },
    emits: ['changeMethod'],
    setup(__props) {
        const error = reactive({
            login: '',
            password: ''
        });
        const login = ref('');
        const password = ref('');
        const signInForm = ref(null);
        const deviceTypeStore = useDeviceTypeStore();
        const sessionStore = useSessionStore();
        const purchaseStore = useTransferPurchaseStore();
        const { captchaShow, captchaToken, loading, captchaCallback, openCaptcha } = useCaptcha();
        const analytics = inject('analytics');
        const buttonSize = computed(() => {
            if (deviceTypeStore.isMobile)
                return 'xs';
            return 'l';
        });
        const props = __props;
        const signin = async () => {
            error.login = '';
            error.password = '';
            loading.value = true;
            const isAuthorized = await sessionModel.signIn(login.value, password.value, captchaToken.value);
            loading.value = false;
            if (isAuthorized?.error) {
                const { email, password } = isAuthorized?.error;
                error.login = email;
                error.password = password;
                analytics?.send('login_error', {
                    type: 'login',
                    method: 'email',
                    message: email ?? password
                });
                return;
            }
            analytics?.setUser(sessionStore.profile?.user_id);
            analytics?.send('login', {
                type: 'login',
                method: 'email',
                user_id: sessionStore.profile?.user_id
            });
            props.onClose();
            // Очистка статуса отображения модального окна отзыва у всех юзеров
            purchaseStore.resetCacheShowModalOnce('feedback');
            purchaseStore.resetCacheShowModalOnce('social');
        };
        onMounted(async () => {
            await nextTick(() => {
                signInForm.value?.focus();
            });
        });
        const isAllDataFilled = computed(() => {
            return login.value && password.value;
        });
        const vkUrl = computed(() => {
            return sessionStore.vkAuthUrl;
        });
        const passwordState = computed(() => {
            return error.password ? 'error' : 'default';
        });
        const loginState = computed(() => {
            return error.login ? 'error' : 'default';
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "signInForm",
                ref: signInForm,
                class: "signin",
                tabindex: "0",
                onKeyup: _withKeys(signin, ["enter"])
            }, [
                _createVNode(_unref(AuthHeader), {
                    title: "ВХОД В АККАУНТ",
                    description: "Пожалуйста, введите данные для входа"
                }),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_unref(Input), {
                            modelValue: login.value,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((login).value = $event)),
                            placeholder: "Email или Логин",
                            "left-icon": "user",
                            state: loginState.value,
                            error: error.login,
                            disabled: _unref(loading)
                        }, null, 8, ["modelValue", "state", "error", "disabled"]),
                        _createVNode(_unref(Input), {
                            modelValue: password.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((password).value = $event)),
                            placeholder: "Пароль",
                            type: "password",
                            "left-icon": "lock",
                            state: passwordState.value,
                            error: error.password,
                            disabled: _unref(loading),
                            password: true
                        }, null, 8, ["modelValue", "state", "error", "disabled"]),
                        _createElementVNode("span", {
                            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('changeMethod', 'recovery'))),
                            class: _normalizeClass(["to-recovery", { disabled: _unref(loading) }])
                        }, " Забыли пароль? ", 2),
                        (_unref(captchaShow))
                            ? (_openBlock(), _createBlock(_unref(Captcha), {
                                key: 0,
                                onOnVerify: _cache[3] || (_cache[3] = (token) => _unref(captchaCallback)(token, signin))
                            }))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    (!_unref(loading))
                        ? (_openBlock(), _createBlock(_unref(VkButton), {
                            key: 0,
                            url: vkUrl.value
                        }, null, 8, ["url"]))
                        : _createCommentVNode("", true),
                    _createVNode(_unref(Button), {
                        class: "signin__buttons__authorization",
                        onClick: signin,
                        type: "primary",
                        size: buttonSize.value,
                        rightIcon: "arrowRight",
                        loading: _unref(loading),
                        disabled: !isAllDataFilled.value || _unref(loading)
                    }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" АВТОРИЗОВАТЬСЯ ")
                        ])),
                        _: 1
                    }, 8, ["size", "loading", "disabled"]),
                    (_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(Button), {
                            key: 1,
                            class: "signin__buttons__create-account",
                            onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('changeMethod', 'signup'))),
                            type: "primary",
                            size: buttonSize.value,
                            "right-icon": "userPlus",
                            disabled: _unref(loading)
                        }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode(" СОЗДАТЬ АККАУНТ "),
                                _createElementVNode("span", { class: "accent" }, "+100 R$", -1)
                            ])),
                            _: 1
                        }, 8, ["size", "disabled"]))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    (!_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(DetailInfo), {
                            key: 0,
                            title: "У Вас нет аккаунта?",
                            description: "Зарегистрируйтесь прямо сейчас!",
                            disabled: _unref(loading),
                            onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('changeMethod', 'signup')))
                        }, null, 8, ["disabled"]))
                        : _createCommentVNode("", true),
                    (_unref(deviceTypeStore).isMobile)
                        ? (_openBlock(), _createBlock(_unref(MobileAgreementText), { key: 1 }))
                        : _createCommentVNode("", true)
                ])
            ], 544));
        };
    }
});
